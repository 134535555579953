import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { connect } from "react-redux";
import { memberActions } from "../../_actions";
import { appConstants } from "../../_constants";
import { ExitPopup } from "../../components/ExitPopup";
import Logo from "../../assets/images/logo.svg";
import get from "lodash/get";
import "./styles.css";

const ChNavbarLight = ({ loggedIn, isMember, dispatch, setCurrentTab, location }) => {
  // List of routes that dont show the event & reservation links
  const linkExclusionList = ["/NewMonthlyMemberSignUp","/NewGiftMemberSignUp", "/NewSupplierMemberSignUp"];
  const popUpInclusionList = ["/NewMonthlyMemberSignUp/"];
  const [showForm, setShowForm] = useState(false);
  const [formActive, setFormActive] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  let pid = location?.pathname?.split('/')?.pop();

  const popUpForm = (e) => {
    const inPath = popUpInclusionList.find(
      path => location.pathname.includes(path)
    );
    if(inPath && !cancelled && e.clientY <= 0 || e.clientX <= 0){
      setShowForm(true);
      setCancelled(true);
    }
  };
  
  const killForm = (e) => {
    const inPath = popUpInclusionList.find(
      path => location.pathname.includes(path)
    );
    if(!formActive && inPath){
      setShowForm(false);
    }
  };
 
  const logout = () => {
    dispatch(memberActions.logout());
  }


  return (
    <nav className="container-light" onMouseLeave={popUpForm} onMouseEnter={killForm}>
      <Navbar collapseOnSelect expand="md" className="nav-links-light navbar-light">
        {loggedIn ? (
          <Navbar.Brand className="m-1 p-1 " href={pid === 'NewSupplierMemberSignUp' ? null : '/main'} title="Coopers Hawk Logo">
            <img src={Logo} className="logo-svg-light hover" alt="Cooper's Hawk Logo" onClick={pid === 'NewSupplierMemberSignUp' ? logout : null} />
          </Navbar.Brand>
        ) : (
          <Navbar.Brand className="m-1 p-1" href="/" title="Coopers Hawk Logo">
            <img src={Logo} className="logo-svg-light" alt="Cooper's Hawk Logo" />
          </Navbar.Brand>
        )}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="pt-3 mb-auto text-center"
        >
          <Nav className="ml-auto" />
          {isMember && (
            <Nav.Link
              href="https://chwinery.com/wine-club/wine-club-newsletter"
              target="_blank"
              className="upper-links"
            >
              Newsletter
            </Nav.Link>
          )}
          <Nav.Link
            href={appConstants.choloUrl}
            target="_blank"
            className="upper-links"
          >
            Order Online
          </Nav.Link>
          {(!linkExclusionList.includes(location.pathname)) && (
            <Nav.Link
              href="https://chwinery.com/locations"
              target="_blank"
              className="upper-links"
            >
              Reservations
            </Nav.Link>
          )}     
          {(!loggedIn && !linkExclusionList.includes(location.pathname)) && (
            <Nav.Link
              href="https://chwinery.com/wine-club/special-events"
              target="_blank"
              className="upper-links"
            >
              Events
            </Nav.Link>
          )}
          <Nav.Link
            href="https://chwinery.com/contact/contact-us"
            target="_blank"
            className="upper-links"
          >
            Contact
          </Nav.Link>
          {loggedIn && (
            <Nav.Link
              eventKey="6"
              className="upper-links"
              onClick={() => dispatch(memberActions.logout())}
            >
              Log Out
            </Nav.Link>
          )}
          <Nav id="collapsed-tabs-light" className="visibledevice">
            <br />

            {isMember && (
              <Nav.Link eventKey="0" onClick={() => setCurrentTab(0)}>
                Dashboard
              </Nav.Link>
            )}

            {isMember && (
              <Nav.Link eventKey="1" onClick={() => setCurrentTab(1)}>
                Points &amp; Rewards
              </Nav.Link>
            )}

            {isMember && (
              <Nav.Link eventKey="2" onClick={() => setCurrentTab(2)}>
                Bottles
              </Nav.Link>
            )}

            {isMember && (
              <Nav.Link eventKey="3" onClick={() => setCurrentTab(3)}>
                Events
              </Nav.Link>
            )}

            {isMember && (
              <Nav.Link eventKey="4" onClick={() => setCurrentTab(4)}>
                Profile
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {showForm && (
        <ExitPopup 
          initShow={showForm}
          display={setFormActive}
          pid={pid}
        />
      )}
    </nav>
  );
};

function mapStateToProps(state) {
  const { member, authentication } = state;
  const loggedIn = get(authentication, "loggedIn", false);
  const isPotential = get(member.data, "membershipType", "") === "Potential";

  const isMember = loggedIn && !isPotential;

  return {
    loggedIn,
    isMember,
  };
}

const connectedChNavbarLight = withRouter(connect(mapStateToProps)(ChNavbarLight));
export { connectedChNavbarLight as ChNavbarLight };
