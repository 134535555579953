import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import LoadingSpinners from "../LoadingSpinners";
import UIButton from "../UIButton";
import { ShippingDetailsGift } from "./shipping-details-gift";
import { RecipientDetails } from "./recipient-details";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { newMemberActions } from "../../_actions";
import { deliveryStateAbbreviations } from "../../_helpers"
import FadeIn from "react-fade-in";
import infoIcon from "../../assets/images/info-16.png";
import GiftCard from "../../assets/gift-images/icons/icon-giftcard@3x.png";
import { Popover, Button, OverlayTrigger } from "react-bootstrap";
import get from "lodash/get";

import "./styles.css";

const DeliveryOptions = ({
  active,
  deliveryType,
  loading,
  dispatch,
  stepForward,
  sendTo,
  handleCollapse,
  showDelivery,
  innerWidth,
}) => {
  const [shippingValid, setShippingValid] = useState(false);
  const [recipientValid, setRecipientValid] = useState(false);

  const canAdvance = () => {
    if (sendTo === "me") {
      return shippingValid;
    } else {
      return recipientValid;
    }
  };

  const selectStates = (
    <Popover id="popover-basic">
      <Popover.Content
        style={{
          backgroundColor: "black",
          border: " 1px solid white",
          color: "white",
          fontFamily: "Oswald",
          letterSpacing: "1px",
        }}
      >
        Delivery option is only available in {deliveryStateAbbreviations()}
      </Popover.Content>
    </Popover>
  );

  if (loading) {
    return <LoadingSpinners />;
  }

  return (
    <div className={`${active ? 'signup-active' : 'inactive'}`}>
      <h4 className="section-header">2. Select Gift Recipient</h4>
      <Collapse in={active && showDelivery} onExited={handleCollapse}>
        <div id="collapse-div">
          <FadeIn>
            <div
              className="container"
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="text-libre" style={{marginBottom: 30}}>
                Wines may be picked up at any Cooper's Hawk Winery &amp;
                Restaurant location or shipped quarterly directly to a home or business.
              </div>

              {deliveryType === "Pickup" && (
                <FadeIn>
                  <div className="hrule" />
                  <h3 className="text">
                    Who is the gift membership for?
                  </h3>
                  <Form.Group as={Row} className="py-2">
                    <Col md={6}>
                      <UIButton
                        text="ME"
                        onSelect={() =>
                          dispatch(newMemberActions.setSendTo("me"))
                        }
                        disabled={sendTo !== "me"}
                      />
                    </Col>
                    <Col md={6}>
                      <UIButton
                        text="A FRIEND"
                        onSelect={() =>
                          dispatch(newMemberActions.setSendTo("friend"))
                        }
                        disabled={sendTo !== "friend"}
                      />
                    </Col>
                  </Form.Group>
                </FadeIn>
              )}

              {deliveryType === "Delivery" && (
                <FadeIn>
                  <p className="text mb-0 pb-0">
                    <img src={infoIcon} className="pb-1 mr-2" alt="info icon" />
                    Quarterly delivery is available in 
                    <OverlayTrigger
                      trigger="focus"
                      placement="bottom"
                      overlay={selectStates}
                    >
                      <Button className="btn-link">select states</Button>
                    </OverlayTrigger>
                    and an adult 21 years or older must be present to sign for the delivery.
                  </p>
                  <div className="hrule" />
                  <Form.Group
                    as={Row}
                    className="py-2 justify-content-center"
                    controlId="clubType"
                  >
                    <Col xs={2} align="right" className="my-auto mr-3">
                      <img
                        style={{ marginLeft: "1em" }}
                        height={50}
                        content
                        src={GiftCard}
                        alt="gift icon"
                        className="gift-icon"
                      />
                    </Col>
                    <Col xs={8} className="my-auto ml-2">
                      <p
                        style={{
                          fontFamily: "Oswald",
                          color: 'black',
                          fontSize: 18,
                          marginBottom: 0,
                        }}
                      >
                        Every quarterly delivery includes tasting notes from our Winemaker and wine pairing suggestions.
                      </p>
                    </Col>
                  </Form.Group>
                  <div className="text">
                    WHERE SHOULD WE SEND THE GIFT MEMBERSHIP?
                  </div>
                  <Form.Group as={Row} className="py-2">
                    <Col md={6}>
                      <UIButton
                        text="SEND TO ME"
                        onSelect={() =>
                          dispatch(newMemberActions.setSendTo("me"))
                        }
                        disabled={sendTo !== "me"}
                      />
                    </Col>
                    <Col md={6}>
                      <UIButton
                        text="SEND TO MY FRIEND"
                        onSelect={() =>
                          dispatch(newMemberActions.setSendTo("friend"))
                        }
                        disabled={sendTo !== "friend"}
                      />
                    </Col>
                  </Form.Group>
                </FadeIn>
              )}

              {sendTo === "friend" && (
                <FadeIn>
                  <div className="hrule" />
                  <RecipientDetails
                    required={sendTo === "friend"}
                    innerWidth={innerWidth}
                    formIsValid={(valid) => setRecipientValid(valid)}
                  />
                </FadeIn>
              )}
              {sendTo === "me" && (
                <FadeIn>
                  <div className="hrule" />
                  <ShippingDetailsGift
                    required={sendTo === "me"}
                    innerWidth={innerWidth}
                    formIsValid={(valid) => setShippingValid(valid)}
                  />
                </FadeIn>
              )}
              {sendTo && 
              <Form.Group as={Row} className="py-2" controlId="clubType" style={deliveryType === 'Delivery' ? {marginTop: sendTo === 'me' ? -38 : -76, marginBottom: -10} : {}}>
                {/* Don't agree with removing this. Bad UX experience
                <Col md={2}>
                  <UIButton text="BACK" onSelect={stepBack} />
                </Col>*/}
                <Col md={10}></Col>
                <Col md={2}>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="next-button" onClick={() => canAdvance() ? stepForward() : null} style={canAdvance() ? {} : {backgroundColor: 'transparent'}} tabIndex={0}>
                      <div className="next-text" style={canAdvance() ? {} : {color: 'gray'}}>
                        NEXT
                      </div>
                    </div>
                    </div>
                </Col>
              </Form.Group>
              }
            </div>
          </FadeIn>
        </div>
      </Collapse>
      {!showDelivery && (
        <FadeIn>
          <div>
            <div
              className="big-icon-wrap mb-2"
              style={{
                justifyContent: "space-between",
                margin: "2em 4em 0 2%",
              }}
            >
              {deliveryType !== "" && (
                <>
                  
                </>
              )}
            </div>
          </div>
        </FadeIn>
      )}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const {
    member,
    newMember,
    staticContent,
    replacementCard,
    paymentForm,
  } = state;
  const url = get(paymentForm, "url", "");
  const loading = get(paymentForm, "loading", false);
  const membershipType = get(member, "data.membershipType", "");
  const price = get(member, "data.costOfMembership", "");
  const memberSignatureOptOut = get(
    member,
    "data.memberSignatureOptOut",
    false
  );
  const shippingAddress = get(newMember, "data.shippingAddress", {});
  const giftLength = get(newMember, "data.giftLength", "");
  const sendTo = get(newMember, "data.sendTo", "");
  const shipDate = get(
    newMember,
    "data.shipDate",
    new Date().toLocaleDateString()
  );
  const clubType =
    ownProps.signup === "profile"
      ? get(member, "data.clubType", "Red")
      : get(newMember, "data.clubType", "");
  const bottleLevel =
    ownProps.signup === "profile"
      ? get(member, "data.bottleLevel", "")
      : get(newMember, "data.bottleLevel", "");
  const deliveryType =
    ownProps.signup === "profile"
      ? get(member, "data.deliveryType", "Pickup")
      : get(newMember, "data.deliveryType", "");

  const expirationDate = get(member, "data.expirationDate", "");

  const cardType = get(
    member,
    "data.storedPaymentAccount.BluePay__Card_Type__c",
    "Card"
  );
  const lastFour = get(
    member,
    "data.storedPaymentAccount.BluePay__Card_Number__c",
    ""
  ).slice(12, 16);
  const expMonth = get(
    member,
    "data.storedPaymentAccount.BluePay__Expiration_Month__c",
    ""
  );
  const expYear = get(
    member,
    "data.storedPaymentAccount.BluePay__Expiration_Year__c",
    ""
  );

  const optOutText = get(staticContent, "data.messages.memberSignature", "");
  const pricingStructure = get(staticContent, "data.pricingStructure", {});
  const cardRequestSuccess = replacementCard.data;
  const cardRequested = replacementCard.requested;

  return {
    memberSignatureOptOut,
    clubType,
    membershipType,
    bottleLevel,
    deliveryType,
    expirationDate,
    cardType,
    lastFour,
    expMonth,
    expYear,
    optOutText,
    cardRequested,
    cardRequestSuccess,
    giftLength,
    pricingStructure,
    sendTo,
    shipDate,
    url,
    lockedPrice: price,
    loading,
  };
}

const connectedDeliveryOptions = withRouter(
  connect(mapStateToProps)(DeliveryOptions)
);
export { connectedDeliveryOptions as DeliveryOptions };
