import React, { useState, useEffect, useReducer, useRef } from "react";
import Overlay from "react-bootstrap/Overlay";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import {
  basketsActions,
  restaurantsActions,
  ordersActions,
  memberActions,
  alertActions,
  newMemberActions,
  paymentActions,
} from "../../_actions";
import {
  Header,
  DateTimeSelector,
  DropDownSelector,
  Footer,
  CholoInput
} from "../../components";
import { contains, find, get, isEmpty, some, reduce } from "lodash";
import infoIcon from "../../assets/images/info-16.png";
import CCimages from "../../assets/images/weaccept.jpg";
import "./styles.scoped.scss";
import { appConstants } from "../../_constants";
import Loader from "react-loader-spinner";
import SecureLS from "secure-ls";
import { createBrowserHistory } from "history";
import { toast } from "react-toastify";
import { isThemeEsquire, pushGTM } from "../../_helpers";
import ThemedBackArrow from "../../components/ThemedBackArrow";
import ReCAPTCHA from "react-google-recaptcha";
import { useRenameDocument } from "../../context/renameDocument";
import { Baskets } from "../../_services/baskets.service";


const formatCurrency = (num) => `$${(Math.round(num * 100)/100).toFixed(2)}`;

let lastToken = '';

const Checkout = ({
  isSymphony,
  discount,
  discountApplied,
  restaurantData,
  basketData,
  basketLoading,
  basketSaved,
  basketError,
  billingSchemes,
  memberData,
  orderData,
  orderError,
  orderPlaced,
  orderLoading,
  hoursData,
  settings,
  loggedIn,
  dispatch,
  history,
  location,
  convertedBottlesCount,
  savedPayments,
  savedPaymentsError,
  savedPaymentsLoading,
  bluepayUrl,
  bluepayFormLoading,
  loadingNewCard,
  selectedMember,
  contacts,
  hasAlcohol,
  dispatchPhoneNumber
}) => {
  let { basketGuid } = useParams();
  useRenameDocument("Checkout");

  const [firstName, setFirstName] = useState(get(memberData, "firstName", "") || "");
  const [lastName, setLastName] = useState(get(memberData, "lastName", "") || "");
  const [email, setEmail] = useState(get(memberData, "email", ""));
  const [phone, setPhone] = useState("");
  const [memberSfId, setMemberSfId] = useState(get(memberData, "sfId", ""));
  const [promoCode, setPromoCode] = useState("");
  const [subscribeChecked, setSubscribeChecked] = useState(true);
  const [deliveryInstructions, setDeliveryInstructions] = useState("");
  const [carMake, setCarMake] = useState("");
  const [carModel, setCarModel] = useState("");
  const [carColor, setCarColor] = useState("");
  const [creditCardNumber, setCreditCardNumber] = useState("");
  const [cvv, setCvv] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("MM");
  const [expiryYear, setExpiryYear] = useState("YYYY");
  const [zipcode, setZipcode] = useState("");
  const [customTip, setCustomTip] = useState("");
  const [percentTip, setPercentTip] = useState();
  const subtotal = get(orderData, "subtotal", 0);
  const tax = get(orderData, "tax", 0);
  const serviceFee = get(orderData, "totalfees", 0);
  const deliveryCharge = get(orderData, "customerhandoffcharge", 0);
  const total = get(orderData, "total", 0);
  const [token, setToken] = useState("");
  const [creditCardErrors, setCreditCardErrors] = useState({});
  const [iframeHeight, setIframeHeight] = useState(300);
  const [showInfo, setShowInfo] = useState(false);
  const target = useRef(null);
  // Diana added 05/07/2021 stores the reference of the orderData before the order is placed
  // in order to check if the ready time has been changed after the order has been placed
  const orderDataBeforePlaced = useRef();

  let storedPaymentAccount = get(memberData, "storedPaymentAccount", null);
  const [selectedCard, setSelectedCard] = useState(0);
  const [selectedPayInStore, setSelectedPayInStore] = useState(false);

  const [paymentError, setPaymentError] = useState(null);
  const [manualEntry, setManualEntry] = useState(false);

  const tipClock = useRef(null);
  const spreedly = useRef(window.Spreedly);
  const bluepayRef = useRef();
  const paymentRef = useRef();
  const [payInStore, setPayInStore] = useState(false);
  const payWithStored = savedPayments && savedPayments.length && !!selectedCard;
  const offerAddCard = appConstants.cardOnFileEnabled && (loggedIn && !storedPaymentAccount);
  const isLoading = !!(bluepayFormLoading || loadingNewCard || savedPaymentsLoading);
  const canRemoveCard = memberData && memberData.membershipType === "Potential";
  const cancelAddCard = loggedIn && memberData && !storedPaymentAccount;
  const isMember = loggedIn && memberData && memberData.membershipType !== "Potential";

  const [monthsOpen, setMonthsOpen] = useState(false);
  const [yearsOpen, setYearsOpen] = useState(false);
  const [applied, setApplied] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const contact = selectedMember && contacts && contacts.find(entry => entry.Id === selectedMember);
  const isEsquire = isThemeEsquire();
  const memberStatus = contact && contact.CHW_Status__c;
  if (!["Active", "UTP", "New"].includes(memberStatus)) {
    // Hide stored payment account for other statuses
    storedPaymentAccount = null;
  }

  const reCaptchaRef = useRef();
  const [reCaptchaError, setReCaptchaError] = useState("");
  const [reCaptchaValue, setReCaptchaValue] = useState(undefined);

  useEffect(() => {
    const messageListener = async e => {
      try {
        let { data, source } = e;

        if (!bluepayRef.current || source !== bluepayRef.current.contentWindow) {
          // Ignore messages that are not from the bluepay iframe
          return;
        }

        if (data.includes('resize_iframe')) {
          data = JSON.parse(data);
          setIframeHeight(data.payload);
          return;
        }

        const responseType = data?.split('|')[0];
        const response = responseType?.length < data?.length ? JSON.parse(data.slice(responseType.length + 1)) : "";
        if (!responseType || data === 'cancel') {
          dispatch(paymentActions.clearOLOPaymentForm());
          return;
        } else if (responseType !== 'approved') {
          const errorMessage = decodeURIComponent(response.MESSAGE);
          alertActions.oloerror(`${errorMessage}. Please try again or enter credit card only for this order`);
          dispatch(paymentActions.clearOLOPaymentForm());
          return;
        } else {
          dispatch(paymentActions.postPayment(response, memberData));
        }

      } catch (e) {
        console.log('### Caught', e);
      }

    };
    !!basketData && ![formatCurrency(subtotal * 0.15), formatCurrency(subtotal * 0.20), formatCurrency(subtotal * 0.25)].includes(formatCurrency(basketData.tip)) && setCustomTip(basketData.tip === 0 ? '' : basketData.tip);

    window.addEventListener('message', messageListener);
    return () => window.removeEventListener('message', messageListener);

  }, []);

  const monthOptions = [
    { label: "MM", value: "MM" },
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "09", value: "09" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
  ];

  const yearOptions = () => {
    var currentYear = new Date().getFullYear(),
      years = [{ label: "YYYY", value: "YYYY" }];
    for (var i = 1; i < 13; i++) {
      let year = currentYear++;
      years.push({
        label: year.toString(),
        value: year.toString(),
      });
    }
    return years;
  };
  const years = yearOptions();

  const getTip = (_) => {
    if (percentTip === 'none') {
      return 0;
    }
    if (percentTip !== 0) {
      return (Math.round(subtotal * percentTip * 100)/100).toFixed(2);
    }
    const customTipNum = parseFloat(customTip);
    if (!isNaN(customTipNum)) {
      return customTipNum;
    }
    return 0;
  };

  const getTotal = (_) => {
    return total;
  };

  const handoffMode = get(basketData, "deliverymode");
  const handoffPreference = get(settings, "handoff");
  const cartItems = get(basketData, "products", []);

  const initFormState = {
    selectedTimeMode: get(basketData, "timemode"),
    selectedTimeWanted: get(basketData, "timewanted"),
    selectedAddress: get(basketData, "deliveryaddress"),
    earliestReadyTime: get(basketData, "earliestreadytime"),
    tip: get(basketData, "tip"),
    handoffMode: handoffMode,
    searchValue: "",
    searchError: "",
    changeset: {
      timeMode: false,
      timeWanted: false,
      deliveryAddress: false,
      tip: false,
      handoffMode: false,
    },
    errorset: [],
  };

  const maskPhone = (value) => {
    let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    value = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    return value;
  };
  const emailRegex = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  const zipcodeRegex = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/);

  const [emailInvalid, setEmailInvalid] = useState(false);
  const [phoneInvalid, setPhoneInvalid] = useState(false);

  const personalInfoValid =
    (emailRegex.test(email) &&
      firstName !== "" &&
      lastName !== "" &&
      phone?.replace(/\D/g, "").length === 10 &&
      payWithStored) ||
    (emailRegex.test(email) &&
      firstName !== "" &&
      lastName !== "" &&
      phone?.replace(/\D/g, "").length === 10);

  const personalInfoFilled =
    (email !== "" &&
      firstName !== "" &&
      lastName !== "" &&
      phone !== "" &&
      payWithStored) ||
    (email !== "" &&
      firstName !== "" &&
      lastName !== "" &&
      phone !== "" &&
      expiryMonth !== "MM" &&
      expiryYear !== "YYYY" &&
      zipcode !== "");

  const formReducer = (state, action) => {
    let changeset = state.changeset;
    switch (action.type) {
      case "handoff-changed":
        return {
          ...state,
          handoffMode: action.data,
          changeset: {
            ...changeset,
            handoffMode: true,
          },
        };
      case "time-selected":
        if (action.data === "asap") {
          return {
            ...state,
            selectedTimeMode: "asap",
            changeset: {
              ...changeset,
              timeMode: true,
            },
          };
        }
        return {
          ...state,
          selectedTimeMode: "advance",
          selectedTimeWanted: action.data,
          changeset: {
            ...changeset,
            timeWanted: true,
          },
        };
      case "address-selected":
        return {
          ...state,
          selectedAddress: action.data,
          changeset: {
            ...changeset,
            deliveryAddress: true,
          },
        };
      case "tip-set":
        return {
          ...state,
          tip: action.data,
          changeset: {
            ...changeset,
            tip: true,
          },
        };
      case "save-changes":
        return {
          ...state,
          changeset: {
            timeMode: false,
            timeWanted: false,
            deliveryAddress: false,
            handoffMode: false,
            tip: false,
          },
        };
      case "revert-changes":
        if (!initFormState.tip) {
          setPercentTip(0);
          setCustomTip("");
        }
        return initFormState;
      case "show-validation-errors":
        return {
          ...state,
          errorset: action.data,
        };
      case "clear-validation-errors":
        return {
          ...state,
          errorset: [],
        };
      default:
        return state;
    }
  };
  const [formState, formDispatch] = useReducer(formReducer, initFormState);

  const dispatchHours = !isEmpty(hoursData)
    ? find(hoursData, { type: formState.handoffMode === "dispatch" ? "dispatch" : "curbsidepickup" })
    : null;

  const save = async (_) => {
    await dispatch(basketsActions.updateBasket(basketGuid, formState));
  };

  const attachInstructions = async (_) => {
   await Baskets.attachInstructions(basketGuid, {...formState, specialinstructions: deliveryInstructions });
  };
  
  const setTip = (_) => {
    if(!isNaN(formState.tip))
{
     dispatch(basketsActions.addTip(basketGuid, formState.tip));
}
  };

  useEffect(() => {
    setApplied(discountApplied);
    if (!basketLoading && !isSubmitted) {
      validateOrder();
    }
  }, [discountApplied]);

  useEffect(
    (_) => {
      const { changeset } = formState;
      if (
        some(
          [
            changeset.timeMode,
            changeset.timeWanted,
            changeset.deliveryAddress,
            changeset.handoffMode,
          ],
          Boolean
        )
      ) {
        save();
        formDispatch({ type: "save-changes" });
      }
    },
    [formState]
  );

  useEffect(
    (_) => {
      if (formState.changeset.tip) {
        setTip();
        formDispatch({ type: "save-changes" });
      }
    },
    [formState.changeset.tip]
  );

  useEffect(() => {
    if (basketData && get(basketData, "id") === basketGuid) {
      let restaurantId = get(basketData, "vendorid");
      if (isEmpty(hoursData) && restaurantId) {
        // fetch restaurant hours if missing
        dispatch(restaurantsActions.getHours(restaurantId));
      }
    } else if (!basketLoading && !isSubmitted) {
      // basket missing or doesn't match guid. fetch it
      // dispatch(basketsActions.getBasket(basketGuid));
      const history = createBrowserHistory({ forceRefresh: true });
      history.replace("/");
    }
  }, [basketData]);


  useEffect(
    (_) => {
      if (basketSaved) {
        dispatch(basketsActions.resetBasket());
        validateOrder();
      }
    },
    [basketSaved]
  );

  useEffect(
    (_) => {
      if (memberData) {
        setFirstName(get(memberData, "firstName") || "");
        setLastName(get(memberData, "lastName") || "");
        setEmail(get(memberData, "email") || "");
        setPhone(get(memberData, "phone") || dispatchPhoneNumber || "");
      }
    },
    [memberData, storedPaymentAccount, dispatchPhoneNumber]
  );

  useEffect((_) => {
    // validate basket right on page load

    const basketProducts = get(basketData, "products", []);
    pushGTM("checkout", "Checkout", basketProducts, { step: 7 });
    dispatch(paymentActions.clearOLOPaymentForm());
    if (formState.handoffMode !== handoffPreference) {
      formDispatch({ type: "handoff-changed", data: handoffPreference });
    } else {
      // TODO: Should validateOrder be in the else case? 
      validateOrder();
      getBillingSchemes();
    }
    setupSpreedly();
    spreedly.current.init(appConstants.spreedlyEnvironment, {
      numberEl: "spreedly-card-number",
      cvvEl: "spreedly-cvv-number",
    });
  }, []);

  useEffect(
    (_) => {
      if (billingSchemes.some(scheme => scheme.type === 'payinstore')) {
        setPayInStore(true);
      }
      else {
        setPayInStore(false);
      }
    },
    [billingSchemes]
  );

  useEffect(
    (_) => {
      setPercentTip(0);
      if (customTip !== '' && percentTip === 0) {
        clearTimeout(tipClock.current);
        tipClock.current = setTimeout(() => {
          formDispatch({ type: "tip-set", data: getTip() });
        }, 2000);
      }
    },
    [customTip, percentTip]
  );

  useEffect(
    (_) => {
      if (percentTip !== 0) {
        formDispatch({ type: "tip-set", data: getTip() });
        setCustomTip("");
      }
    },
    [percentTip]
  );


  useEffect(
    () => {
      if (savedPaymentsError) {
        setSelectedCard(0);
      }
    },
    [savedPaymentsError]
  );

  // Select the saved card
  useEffect(
    () => {
      if (savedPayments && savedPayments.length) {
        setSelectedCard(1);
      }
    },
    [savedPayments]
  );

  // Select pay in store option
  useEffect(
    () => {
      if (!!selectedPayInStore) {
        setSelectedCard(-1);
      }
    },
    [selectedPayInStore]
  );

  // Update pay in store option when selecting a card
  useEffect(
    () => {
      if (selectedCard > -1) {
        setSelectedPayInStore(false);
      }
      else {
        setSelectedPayInStore(true);
      }
    },
    [selectedCard]
  );


  // Get billing accounts
  const prevStoredPaymentRef = useRef();
  useEffect(
    () => {
      if (
        storedPaymentAccount &&
        JSON.stringify(prevStoredPaymentRef.current) !== JSON.stringify(storedPaymentAccount)
      ) {
        prevStoredPaymentRef.current = storedPaymentAccount;
        dispatch(paymentActions.getPayments());
      }

      if (!storedPaymentAccount && savedPayments && savedPayments.length) {
        dispatch(paymentActions.clearPayments());
      }
    },
    [storedPaymentAccount]
  );

  const setupSpreedly = () => {
    if (spreedly.current) {
      spreedly.current.on("ready", () => {
        spreedly.current.setFieldType("text");
        spreedly.current.setNumberFormat("prettyFormat");
        spreedly.current.setStyle(
          "number",
          "padding:15px;line-height:1.5em;font-size:18px;font-style:italic;width:100%;font-weight: 200;font-family:'Roboto', sans-serif"
        );
        spreedly.current.setStyle(
          "cvv",
          "padding:15px;line-height:1.5em;font-size:18px;font-style:italic;font-weight: 200;font-family:'Roboto Condensed',sans-serif;"
        );
        spreedly.current.setPlaceholder("number", "Credit Card Number");
        spreedly.current.setPlaceholder("cvv", "CVV");
      });
    }
  };

  const createPotentialMember = async () => {
    if (
      loggedIn ||
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      !subscribeChecked
    ) {
      return;
    } else {
      const member = {
        FirstName: firstName,
        LastName: lastName,
        CHW_Membership_Type__c: "Potential",
        Email: email,
        CHW_Guest__c: true
      };
      await dispatch(newMemberActions.signupPotential({ member, returnUrl: "/" }));
    }
  };

  useEffect(
    (_) => {
      if (orderPlaced) {
        // Diana added 05/07/2021 checks if the readytime before order is placed is the same as the readytime after order is placed. If not, it's shouwing a pop up
        if (
          orderDataBeforePlaced.current &&
          orderDataBeforePlaced.current.readytime !== orderData.readytime
        ) {
          alertActions.warning(`Initial asap time has changed due to delay in placing order.`);
        }
        if (subscribeChecked) createPotentialMember();
        goToConfirmation();
      }
    },
    [orderPlaced]
  );

  const cancelEnterCard = () => {
    setManualEntry(false);
  }

  const goToBag = (_) => {
    history.push(`/menu/${get(restaurantData, "slug")}?bagOpen=1`);
  };

  const goToLocator = (_) => {
    history.push("/");
  };

  const goToConfirmation = (_) => {
    history.push(`/confirmation/${basketGuid}`);
  };

  const loginAsMember = () => {
    const checkoutURL = `/checkout/${basketGuid}`;
    dispatch(memberActions.setLoginDestination(checkoutURL));
    history.push("/login-olo?continueAsType=member");
  };

  const validateOrder = async (_) => {
    await dispatch(ordersActions.validateOrder(basketGuid));
    if(percentTip === undefined && basketData && basketData.allowstip) {
      const tip = (basketData.suggestedtippercentage || 0) * .01;
      setPercentTip(tip);
    }
  };

  const getBillingSchemes = (_) => {
    dispatch(ordersActions.getBillingSchemes(basketGuid));
  };

  const closeError = (_) => {
    dispatch(ordersActions.reset());
    dispatch(basketsActions.resetBasket());
    formDispatch({ type: "revert-changes" });
  };

  const fieldHasError = (field) => contains(formState.errorset, field);

  const placeOrder = async (_) => {
    if(handoffMode==='dispatch' && deliveryInstructions !== '') {
    await attachInstructions();
    }
    save();
    orderDataBeforePlaced.current = orderData;
    formDispatch({ type: "clear-validation-errors" });
    setCreditCardErrors({});
    const requiredFields = {
      firstName,
      lastName,
      email,
      phone,
      expiryMonth,
      expiryYear,
      // cvv,
      zipcode,
    };
    let missing = [];
    for (let field in requiredFields) {
      const val = !!requiredFields[field];
      if (!val) {
        missing.push(field);
      }
    }
    formDispatch({ type: "show-validation-errors", data: missing });

    if (reCaptchaValue) {
      if (personalInfoValid) {
        const validation = await dispatch(ordersActions.validateOrder(basketGuid));
        if (!validation) {
          return;
        }

        if (convertedBottlesCount) {
          const memberInfo = await dispatch(memberActions.getMember());

          if (!validation || !memberInfo) {
            dispatch(alertActions.oloerror("Order failed. Try again."));
            return;
          }
          if (convertedBottlesCount > memberInfo.openOnlineBottles) {
            dispatch(alertActions.oloerror("You have more converted bottles than open bottle"));
            return;
          }
        }

        if (handoffMode === "curbside") {
          const makeCustomField = basketData.customfields.find(customField => customField.label && customField.label.toLowerCase() === "make");

          if (makeCustomField) {
            const makeData = {
              id: makeCustomField.id,
              value: carMake
            }
            await dispatch(basketsActions.setCustomField(basketGuid, makeData));
          }

          const modelCustomField = basketData.customfields.find(customField => customField.label && customField.label.toLowerCase() === "model");

          if (modelCustomField) {
            const modelData = {
              id: modelCustomField.id,
              value: carModel
            }
            await dispatch(basketsActions.setCustomField(basketGuid, modelData));
          }

          const colorCustomField = basketData.customfields.find(customField => customField.label && customField.label.toLowerCase() === "color");

          if (colorCustomField) {
            const colorData = {
              id: colorCustomField.id,
              value: carColor
            }
            await dispatch(basketsActions.setCustomField(basketGuid, colorData));
          }
        }

        const memberNumberCustomField = basketData.customfields.find(customField => customField.label && customField.label.toLowerCase().startsWith("wine club member number"));
        if (memberNumberCustomField) {
          if (contact && contact.CHW_Member_Number__c) {
            const memberNumberData = isSymphony ?  
            {
              id: memberNumberCustomField.id,
              value: `${contact.CHW_Member_Number__c}|${memberData.lastName}`
            } :
            {
              id: memberNumberCustomField.id,
              value: contact.CHW_Member_Number__c
            };

            await dispatch(basketsActions.setCustomField(basketGuid, memberNumberData));
          }
          else {
            await dispatch(basketsActions.removeCustomField(basketGuid, memberNumberCustomField.id));
          }
        }

        if (spreedly.current && !payWithStored && !selectedPayInStore && total !== 0) {
          spreedly.current.removeHandlers();
          setupSpreedly();
          spreedly.current.on("paymentMethod", (token, paymentMethod) => {
            const { card_type, number } = paymentMethod;
            if (token === lastToken) {
              return;
            }
            lastToken = token;
            dispatch(ordersActions.setOrderContactInfo({
              firstname: firstName,
              lastname: lastName,
              emailaddress: email,
              contactnumber: phone?.replace(/\D/g, ""),
              cardnumber: paymentMethod.number,
            }));

            const requestBody = {
              token,
              billingmethod: "creditcard",
              usertype: loggedIn ? "user" : "guest",
              orderref: memberSfId,
              // tokenized values
              cardnumber: "{{ credit_card_number }}",
              cvv: "{{ credit_card_verification_value }}",
              expirymonth: "{{ credit_card_month }}",
              expiryyear: "{{ credit_card_year }}",
              zip: "{{ credit_card_zip }}",
              contactnumber: phone?.replace(/\D/g, ""),
            };

            const authToken = (new SecureLS()).get("oat");
            if (loggedIn && authToken) {
              requestBody.authtoken = authToken;
              requestBody.saveonfile = false;
              requestBody.contactId = selectedMember;
              requestBody.reCaptchaToken = reCaptchaValue;
            } else {
              requestBody.firstname = firstName;
              requestBody.lastname = lastName;
              requestBody.emailaddress = email;
              requestBody.reCaptchaToken = reCaptchaValue;
            }

            dispatch(
              ordersActions.submitOrderByProxy(basketGuid, requestBody)
            );
          });
          spreedly.current.on("errors", (errors) => {
            console.log("Spreedly tokenization errors: ");
            console.log(errors);
            setCreditCardErrors(reduce(errors, (acc, err) => {
              acc[err.attribute] = err.message;
              return acc;
            }, {}));
            paymentRef.current.scrollIntoView({ behavior: "smooth" });
          });

          if (expiryMonth === "MM") {
            setCreditCardErrors({ month: "Missing month" });
            paymentRef.current.scrollIntoView({ behavior: "smooth" });
          } else if (expiryYear === 'YYYY') {
            console.log("Missing year");
            setCreditCardErrors({ year: "Missing year" });
            paymentRef.current.scrollIntoView({ behavior: "smooth" });
          } else if (!zipcode) {
            setCreditCardErrors({ zip: "Missing zip" });
            paymentRef.current.scrollIntoView({ behavior: "smooth" });
          } else {
            spreedly.current.tokenizeCreditCard({
              first_name: firstName,
              last_name: lastName,
              month: expiryMonth,
              year: expiryYear,
              email: email,
              zip: zipcode,
            });
          }
        } else {
          dispatch(ordersActions.setOrderContactInfo({
            firstname: firstName,
            lastname: lastName,
            emailaddress: email,
            contactnumber: phone?.replace(/\D/g, ""),
            cardnumber: selectedCard > 0 ? savedPayments[selectedCard - 1].cardsuffix : "",
          }));
          const orderData = {
            billingmethod: "creditcard",
            usertype: loggedIn ? "user" : "guest",
            orderref: memberSfId,
          };
          const authToken = (new SecureLS()).get("oat");

          if (selectedCard > 0) {
            orderData.billingmethod = "billingaccount";
            orderData.billingaccountid = savedPayments[selectedCard - 1].accountid;
          }
          else if (selectedPayInStore) {
            orderData.billingmethod = "cash";
          }

          orderData.contactnumber = phone?.replace(/\D/g, "");

          if (loggedIn && authToken) {
            orderData.authtoken = authToken;
            orderData.contactId = selectedMember;
            orderData.reCaptchaToken = reCaptchaValue;
          } else {
            orderData.firstname = firstName;
            orderData.lastname = lastName;
            orderData.emailaddress = email;
            orderData.reCaptchaToken = reCaptchaValue;
          }

          dispatch(ordersActions.submitOrder(basketGuid, orderData));
        }
      } else {
        document.querySelector(".scroll-container").scrollTop = 0;
      }
    } else {
      setReCaptchaError("Complete the ReCAPTCHA checkbox above");
    }
  };

  const fieldError = (message = "This field is required") => (
    <p className="validation-error">{message}</p>
  );

  const removeStoredPayment = () => {
    dispatch(
      alertActions.olosuccess(
        "Are you sure you want to remove the payment method?",
        "Remove Payment",
        () => {
          toast.dismiss();
          dispatch(paymentActions.removePayment(selectedMember));
        },
        "REMOVE",
      )
    );
  };

  const linktoProfile = () => {
    if (phone?.replace(/\D/g, "").length !== 10 && loggedIn) {
      history.push('/profile-olo');
    }
  };

  const personalInfoBlock = (_) => {
    return (
      <div className="row justify-content-center mb-md-4 mb-sm-0">
        <div className="section d-flex flex-column col-md-8 col-sm-8 m-4 bg-white">
          <div className="section-header d-flex flex-row justify-content-between">
            <h3 className="section-title flex-md-grow-1 p-4">
              YOUR INFORMATION
            </h3>
          </div>

          <div className="d-flex flex-row flex-wrap justify-content-between pt-4 pb-0 pl-2 pr-2">
            <div className="d-flex flex-column col-md-6 col-sm-12 p-0 px-md-1">
              <CholoInput
                isValid={firstName !== ""}
                isSubmitted={isSubmitted}
                errorMessage="Please enter your first name"
                placeholder="First name"
                value={firstName}
                onTextChanged={(value) => setFirstName(value)}
                autoComplete="given-name"
                maxLength={36}
                disabled={loggedIn}
              />
            </div>
            <div className="d-flex flex-column col-md-6 col-sm-12 p-0 px-md-1">
              <CholoInput
                isValid={lastName !== ""}
                isSubmitted={isSubmitted}
                errorMessage="Please enter your last name"
                placeholder="Last name"
                value={lastName}
                onTextChanged={(value) => setLastName(value)}
                autoComplete="family-name"
                maxLength={36}
                disabled={loggedIn}
              />
            </div>
            <div className="d-flex flex-column col-md-6 col-sm-12 p-0 px-md-1">
              <CholoInput
                isValid={emailRegex.test(email)}
                isSubmitted={isSubmitted}
                errorMessage="Please enter a valid email"
                placeholder="Email Address"
                value={email}
                onTextChanged={(value) => setEmail(value)}
                autoComplete="off"
                disabled={loggedIn}
              />
              
            </div>
            <div className="d-flex flex-column col-md-6 col-sm-12 p-0 px-md-1" onClick={() => linktoProfile()}>
              <CholoInput
                isValid={phone?.replace(/\D/g, "").length === 10}
                isSubmitted={loggedIn ? true : isSubmitted}
                errorMessage={loggedIn ? "Please click update profile to enter a phone number." : "Please enter area code and phone number, numbers only"}
                placeholder="Phone Number xxx-xxx-xxxx"
                value={phone}
                onTextChanged={(value) => setPhone(maskPhone(value))}
                autoComplete="tel"
                disabled={loggedIn}
              />
            </div>
            {handoffMode === 'curbside' && restaurantData?.supportedarrivalmessagehandoffmodes?.includes('CurbsidePickup') && 
              <span style={{padding: '0 5px'}}>
                <img src={infoIcon} className="info-icon" alt="info icon" />
              <p className='email-subtext'>Please ensure you have access to the above email as it will be used to communicate "I'm here" to the restaurant staff.</p>
              </span>}
            {!!loggedIn && (
              <div className="d-flex flex-column pb-2 px-2">
                <span
                  onClick={() => history.push('/profile-olo')}
                  className="profile-link"
                >
                  Update Profile
                </span>
              </div>
            )}
          </div>
          {!loggedIn && (
            <div className="d-flex flex-row">
              <div className="checkout-field d-flex flex-column col-12 p-3">
                <div className="custom-control custom-checkbox pl-3">
                  <input
                    type="checkbox"
                    className="checkout custom-control-input"
                    checked={subscribeChecked}
                    onChange={(e) => setSubscribeChecked(!subscribeChecked)}
                    id="subscribeCheckbox"
                  />
                  <label
                    className="custom-control-label"
                    for="subscribeCheckbox"
                  >
                    Send me emails with news and offers from Cooper’s Hawk.
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  const deliveryAddressBlock = (_) => {
    return (
      <div className="row justify-content-center mb-md-4 mb-sm-0">
        <div className="section d-flex flex-column col-md-8 col-sm-8 m-4 bg-white">
          <div className="section-header d-flex flex-row justify-content-between">
            <div className="section-title flex-md-grow-1 p-4">
              DELIVERY ADDRESS
            </div>
            <button className="link btn p-4" onClick={goToBag}>
              Edit
            </button>
          </div>
          <div className="d-flex flex-row pl-4 pr-4 pt-4">
            <div className="section-title">
              {`${get(formState.selectedAddress, "streetaddress", "")}, ${get(
                formState.selectedAddress,
                "city",
                ""
              )}, ${get(formState.selectedAddress, "zipcode", "")}`}
            </div>
          </div>
          <div className="d-flex flex-row p-4">
            <div className="checkout-field d-flex flex-column col-12 p-0">
              <input
                value={deliveryInstructions}
                aria-label="delivery instructions"
                onChange={(e) => setDeliveryInstructions(e.target.value)}
                placeholder={
                  "Add delivery instructions ie. gate code, contactless delivery, etc."
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const pickupDetailsBlock = (_) => {
    return (
      <div className="row justify-content-center mb-md-4 mb-sm-0">
        <div className="section d-flex flex-column col-md-8 col-sm-8 m-4 bg-white">
          <div className="section-header d-flex flex-row justify-content-between">
            <div className="section-title flex-md-grow-1 p-4">
              CURBSIDE PICKUP INSTRUCTIONS
            </div>
          </div>
          <div className="section-title d-flex flex-row p-4">CAR DETAILS</div>
          <div className="d-flex flex-row flex-wrap justify-content-between">
            <div className="checkout-field d-flex flex-column col-md-4 col-sm-12 pl-4 pr-sm-4 pr-md-2 pb-4">
              <input
                aria-lanel="car make"
                value={carMake}
                onChange={(e) => setCarMake(e.target.value)}
                placeholder={"Make"}
              />
            </div>
            <div className="checkout-field d-flex flex-column col-md-4 col-sm-12 pl-4 pr-sm-4 pr-md-2  pb-4">
              <input
                aria-label="car model"
                value={carModel}
                onChange={(e) => setCarModel(e.target.value)}
                placeholder={"Model"}
              />
            </div>
            <div className="checkout-field d-flex flex-column col-md-4 col-sm-12 pl-4 pr-sm-4 pr-md-2 pb-4">
              <input
                aria-label="car color"
                value={carColor}
                onChange={(e) => setCarColor(e.target.value)}
                placeholder={"Color"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const restaurantDetailsBlock = (_) => {
    if (!restaurantData) {
      return null;
    }
    return (
      <div className="row justify-content-center mb-md-4 mb-sm-0">
        <div className="section d-flex flex-column col-md-8 col-sm-8 m-4 bg-white">
          <div className="section-header d-flex flex-row justify-content-between">
            <h3 className="section-title flex-md-grow-1 pt-4 pb-4  pl-4 p-0">
              ORDERING FROM
            </h3>
            <button className="link btn p-4" onClick={goToLocator}>
              Change Store
            </button>
          </div>

          <div className="d-flex flex-row p-0">
            <div className="info-group d-flex flex-column p-4">
              <div className="d-flex flex-row justify-content-start mt-2 mb-2">
                <div className="section-title">
                  COOPER'S HAWK - {restaurantData.name}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start mt-1 mb-1">
                <div className="details">
                  {`${restaurantData.streetaddress} ${restaurantData.city}, ${restaurantData.state
                    }`}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start mt-1 mb-1">
                <a
                  className="details link"
                  href={`tel:${restaurantData.telephone}`}
                >
                  {restaurantData.telephone}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const timeBlock = (_) => {
    if (!dispatchHours) {
      return null;
    }

    const businessHours = hoursData && hoursData.find(entry => entry.type === "business");
    return (
      <div className="row justify-content-center mb-md-4 mb-sm-0">
        <div className="payment section d-flex flex-column col-md-8 col-sm-8 m-4 bg-white">
          <div className="section-header d-flex flex-row justify-content-start">
            <h3 className="section-title p-4">
              {`${handoffMode === "dispatch" ? "DELIVERY" : "PICKUP"} TIME`}
            </h3>
          </div>
          <div className="d-flex flex-row p-0 p-sm-4 justify-content-center">
            <div className="d-flex flex-column col-12 p-0">
              <DateTimeSelector
                selected={
                  formState.selectedTimeMode === "asap"
                    ? "asap"
                    : formState.selectedTimeWanted
                }
                hours={get(dispatchHours, "ranges", [])}
                onChange={(time) => {
                  formDispatch({ type: "time-selected", data: time });
                }}
                containerclassName="d-flex flex-row p-4"
                earliestReadyTime={get(basketData, "earliestreadytime")}
                businessHours={businessHours}
                timezone={restaurantData && restaurantData.utcoffset}
                showASAPTime
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const paymentBlock = (_) => {
    return (
      <div className="row justify-content-center mb-md-4 mb-sm-0" ref={paymentRef}>
        <div className="payment section d-flex flex-column col-md-8 col-sm-8 m-4 bg-white">
          <div className="section-header d-flex flex-row justify-content-between">
            <h3 className="section-title flex-md-grow-1 p-4">
              PAYMENT DETAILS
            </h3>
          </div>
          {!appConstants.cardOnFileEnabled && (
            <>
              <div className="d-flex flex-row p-4">
                <div className="d-flex flex-column text-danger">
                  We are experiencing issues with charging stored credit cards. Please enter credit card information below for this order
                </div>
              </div>
            </>
          )}

          {isLoading ? (
            <div>
              <Loader
                type="ThreeDots"
                color="#a47f51aa"
                height={15}
                width={15}
                style={{ textAlign: 'center' }}
              />
            </div>
          ) : total === 0 ? (
            <>
              <div className="details mt-auto text-center">
                No payment is required for this order
              </div>
            </>
          ) : (
            <>
              {(payInStore && !(offerAddCard && !manualEntry)) && (
                <>
                  <div className="d-flex flex-row p-4">
                    <div className="d-flex flex-column"></div>
                    <div className="custom-control custom-radio pl-2">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="payInStore"
                        name="memberInStoreOption"
                        onChange={(e) => {
                          setSelectedPayInStore(true);
                          setPercentTip('none');
                          }
                        }
                        checked={selectedPayInStore}
                      />
                      <label
                        for="payInStore"
                        className="custom-control-label payment-radio-label ml-3"
                      >
                        Pay in Restaurant
                      </label>
                    </div>
                  </div>
                </>
              )}

              {offerAddCard && !manualEntry && (
                <>
                  {!!bluepayUrl && (
                    <iframe
                      src={`${bluepayUrl}&enhancedresponse=true&cardnumbernumericonly=true&invalidcreditcardevent=true&invalidcvvevent=true&invalidexpiryevent=true`}
                      style={{ height: iframeHeight, width: '100%', border: 0 }}
                      ref={bluepayRef}
                      scrolling="no"
                    ></iframe>
                  )}

                  {!bluepayUrl && (
                    <>
                      <div className="row justify-content-center mb-5">
                        <div>
                          {!bluepayFormLoading && (
                            <div>
                              <button
                                className="save-btn save-card col-12"
                                onClick={() => {
                                  dispatch(paymentActions.getOloPaymentForm(selectedMember, firstName, lastName));
                                }}
                              >
                                Save a credit card on your account
                              </button>
                              <div className="save-cart-text" onClick={() => setManualEntry(true)}>
                                or enter a credit card only for this order
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {((storedPaymentAccount && !savedPaymentsError) || (payInStore && !(offerAddCard && !manualEntry))) && (
                <>
                  {savedPayments && (
                    <>
                      {savedPayments.map((payment, index) => (
                        <div className="d-flex flex-row p-4">
                          <div className="d-flex flex-column">
                            <div className="custom-control custom-radio pl-2">
                              <input
                                type="radio"
                                className="custom-control-input"
                                id={`payWithStored${index}`}
                                name="memberPaymentOption"
                                onChange={(e) => setSelectedCard(index + 1)}
                                checked={selectedCard === index + 1}
                              />
                              <label
                                className="custom-control-label payment-radio-label ml-3 removeCardContainer"
                                htmlFor={`payWithStored${index}`}
                              >
                                {payment.cardtype}, ending in {payment.cardsuffix}
                                {!!canRemoveCard && (
                                  <div
                                    className="removeText"
                                    onClick={() => removeStoredPayment(payment)}>
                                    remove
                                  </div>
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  <div className="d-flex flex-row p-4">
                    <div className="d-flex flex-column">
                      <div className="custom-control custom-radio pl-2">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="payWithNew"
                          name="memberPaymentOption"
                          onChange={(e) =>{
                            const tip = (basketData.suggestedtippercentage || 0) * .01;
                            setPercentTip(tip);
                            setSelectedCard(0)
                          }
                        }
                          checked={!selectedCard}
                        />
                        <label
                          for="payWithNew"
                          className="custom-control-label payment-radio-label ml-3"
                        >
                          {(savedPayments && savedPayments.length > 0) ? "Use a different card" : "Enter a credit card for this order only"}
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}

            </>
          )}

          <div
            className={`${(isLoading || payWithStored || selectedPayInStore || (offerAddCard && !manualEntry) || total === 0) ? "d-none" : "d-flex"
              } flex-row flex-wrap justify-content-between pt-4 pl-2 pr-2 mb-4`}
          >
            {!spreedly.current && (
              <div className="checkout-field d-flex flex-column col-12 pb-4">
                <CholoInput
                  isValid={creditCardErrors.number}
                  isSubmitted={isSubmitted}
                  errorMessage={creditCardErrors.number}
                  placeholder="Credit Card Number"
                  value={creditCardNumber}
                  onTextChanged={value => setCreditCardNumber(value)}
                />
              </div>
            )}
            {spreedly.current && (
              <div className="d-flex flex-column col-12 mb-4">
                <div id="spreedly-card-number" className={creditCardErrors.number ? "spreedly-field error" : "spreedly-field"} />
                {creditCardErrors.number && (
                  <div className="validation-message">{creditCardErrors.number}</div>
                )}
              </div>
            )}
            <div className="checkout-field d-flex flex-column col-sm-12 col-md-5">
              <div className="d-flex flex-row p-0 justify-content-center">
                <div className="checkout-field d-flex flex-column col-6 p-0">
                  <div className="flex-column">
                    <DropDownSelector
                      inputStyle={creditCardErrors.month ? { borderColor: 'red' } : {}}
                      value={expiryMonth}
                      items={monthOptions}
                      onClick={(_) => setMonthsOpen(!monthsOpen)}
                      onChange={(item) => {
                        if (item !== expiryMonth) {
                          setCreditCardErrors({ ...creditCardErrors, month: undefined })
                        }
                        setExpiryMonth(item);
                        setMonthsOpen(false);
                      }}
                      isOpen={monthsOpen}
                      containerClass="dropdown-cc"
                    />
                    {/* <div>
                      <Form.Control
                        as="select"
                        onChange={(e) => setExpiryMonth(e.target.value)}
                        value={expiryMonth}
                        className="input-field"
                      >
                        {monthOptions.map((item) => (
                          <option key={item.label} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Control>
                    </div> */}
                    <br />
                    {personalInfoFilled && expiryMonth === "MM" && fieldError()}
                  </div>
                </div>

                <div className="checkout-field d-flex flex-column col-6 p-0 pl-1">
                  <DropDownSelector
                    inputStyle={creditCardErrors.year ? { borderColor: 'red' } : {}}
                    value={expiryYear}
                    items={yearOptions()}
                    onClick={(_) => setYearsOpen(!yearsOpen)}
                    onChange={(item) => {
                      if (item !== expiryYear) {
                        setCreditCardErrors({ ...creditCardErrors, year: undefined })
                      }
                      setExpiryYear(item);
                      setYearsOpen(false);
                    }}
                    isOpen={yearsOpen}
                    containerClass="dropdown-cc"
                  />
                  {/* <div className="input-field"> */}
                  {/* <Form.Control
                    id="card-selector"
                    as="select"
                    className="input-field"
                    style={{ backgroundColor: "white" }}
                    onChange={(e) => setExpiryYear(e.target.value)}
                    value={expiryYear}
                  >
                    {years.map((item) => (
                      <option key={item.label} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Control> */}
                  {/* </div> */}
                  <br />
                  {personalInfoFilled && expiryYear === "YYYY" && fieldError()}
                </div>
              </div>
            </div>
            {!spreedly.current && (
              <div className="checkout-field d-flex flex-column col-sm-12 col-md-3">
                <input
                  aria-label="cvv"
                  value={cvv}
                  onChange={(e) => setCvv(e.target.value)}
                  placeholder={"CVV"}
                />
              </div>
            )}
            {spreedly.current && (
              <div className="spreedly-field-wrapper d-flex flex-column col-sm-12 col-md-3 mb-4">
                <div id="spreedly-cvv-number" className="spreedly-field" />
              </div>
            )}
            <div className="spreedly-field-wrapper d-flex flex-column col-sm-12 col-md-4">
              <div className="spreedly-field d-flex flex-column">
                <input
                  className="spreedly-input"
                  value={zipcode}
                  aria-lanel="zip code"
                  onChange={(e) => setZipcode(e.target.value) && setCreditCardErrors({ ...creditCardErrors, zip: undefined })}
                  placeholder={"Zip code"}
                  autoComplete="postal-code"
                  style={creditCardErrors.zip ? { borderColor: 'red' } : {}}
                />
                <br />
                {personalInfoFilled &&
                  !zipcodeRegex.test(zipcode) &&
                  fieldError()}
              </div>
            </div>
            {cancelAddCard &&
              <div className="cancelBtnView">
                <button className="cancelBtn btn" onClick={cancelEnterCard}>
                  Cancel
                </button>
              </div>
            }
            <div><center><img src={CCimages} width="50%" height="25%" alt="ccimages" /></center></div>
          </div>

          {!bluepayUrl && (
            <div className="error-wrapper d-flex flex-column col-sm-12 col-md-3 mb-4">
              {["month", "year", "ccv", "zip"].map(key => {
                if (creditCardErrors[key]) {
                  return <div className="validation-message">{creditCardErrors[key]}</div>;
                }
              })}
            </div>
          )}
        </div>
      </div>
    );
  };
  const couponBlock = (_) => {
    return (!isMember && !isEsquire && handoffMode !== "dispatch" && (
      <div className="row justify-content-center mb-md-4 mb-sm-0">
        <div className="section d-flex flex-column col-md-8 col-sm-8 m-4 bg-white">
          <div className="section-header d-flex flex-row justify-content-between">
            <div className="section-title flex-md-grow-1 p-4">
              Coupon
            </div>
          </div>

          <div className="d-flex flex-row flex-wrap justify-content-between pt-4 pb-4 pl-2 pr-2">
            <div className="d-flex flex-column col-8 p-0 px-2">
              <CholoInput
                isRequired={false}
                disabled={discountApplied}
                isValid={promoCode?.toUpperCase() !== "MEMBER_DISCOUNT" || promoCode == ""}
                errorMessage={"Discount for members only!"}
                placeholder="Coupon Code"
                value={promoCode}
                onTextChanged={(value) => setPromoCode(value)}
                autoComplete="off"
              />
            </div>

            <div className="d-flex flex-column col-3 p-0 pt-2 px-auto">
              <button
                style={{ height: "65px" }}
                className={`save-btn ${promoCode?.toUpperCase() === "MEMBER_DISCOUNT" && "disable"}`}
                onClick={() => {
                  if (promoCode?.toUpperCase() === "MEMBER_DISCOUNT") return;
                  if (discountApplied) {
                    dispatch(basketsActions.coupon(basketGuid, false));
                    setPromoCode("");
                  }
                  else {
                    dispatch(basketsActions.coupon(basketGuid, true, promoCode));
                  }
                }}
              >
                {discountApplied ? "Remove" : "Apply"}
              </button>
            </div>
          </div>
        </div>
      </div>
    ));
  };
  const orderItemsBlock = (_) => {
    return (
      <div className="row justify-content-center mb-md-4 mb-sm-0">
        <div className="section d-flex flex-column col-md-8 col-sm-8 m-4 bg-white">
          <div className="section-header d-flex flex-row justify-content-between">
            <h3 className="section-title flex-md-grow-1 p-4">Your Order</h3>
            <button className="link btn p-4" onClick={goToBag}>
              Edit Order
            </button>
          </div>

          <div className="d-flex flex-row p-0">
            <div className="info-group d-flex flex-column col-12 pl-3 pr-2 p-0">
              {cartItems.map((item) => (
                <div className="d-flex flex-row order-item">
                  <div className="d-flex flex-column col-1 pb-4 pt-4">
                    <div className="section-title">{item.customdata !== "complimentary" ? item.quantity : ""}</div>
                  </div>
                  <div className="d-flex flex-column col-7 col-sm-8 pb-4 pt-4 p-0">
                    <div className="d-flex flex-row">
                      <div className="section-title">
                        {item.name}&nbsp;&nbsp;
                        <span className="cartCompItemQuant">{item.customdata == "complimentary" ? "quantity based on order size" : ""}</span>
                        {`${!isEmpty(item.recipient) ? `(${item.recipient})` : ""
                          }`}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="details">
                        <span>
                          {item.choices.map((choice, index) => (
                            <span>
                              {choice.cost !== 0 ? (
                                <strong>Add {choice.name}</strong>
                              ) : (
                                choice.name
                              )}

                              {index < item.choices.length - 1 ? ", " : ""}
                            </span>
                          ))}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column col-4 col-sm-3 pb-4 pt-4" style={{ alignItems: "flex-end" }}>
                    <div className="section-title">{formatCurrency(item.totalcost)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const tipBlock = (_) => {
    return (
      <div className="row justify-content-center mb-md-4 mb-sm-0">
        <div className="section d-flex flex-column col-md-8 col-sm-8 m-4 bg-white">
          <div className="section-header d-flex flex-row justify-content-start">
            {handoffMode === 'dispatch' ? 
             <div className="section-title p-4">DRIVER TIP</div> : 
             <div className="section-title p-4">TIP - 100% goes to the restaurant staff</div>}
          </div>
          <div className="d-flex flex-row flex-wrap justify-content-between p-4">
            <div
              className={`tip-option d-flex flex-column justify-content-center align-items-center mb-sm-4${!!basketData && basketData.tip !== 0 && 
                (formatCurrency(basketData.tip) === formatCurrency(subtotal * 0.15) || formatCurrency(basketData.tip - .01) === formatCurrency(subtotal * 0.15)) ? " selected" : ""
                }`}
              onClick={(e) => {
                setPercentTip(0.15);
              }}
            >
              <div className="tip-option-text">
                <span className="percent">15%</span>
                <span className="ml-2 details">
                  {formatCurrency(subtotal * 0.15)}
                </span>
              </div>
            </div>
            <div
              className={`tip-option d-flex flex-column justify-content-center align-items-center mb-sm-4${!!basketData && basketData.tip !== 0 && 
                (formatCurrency(basketData.tip) === formatCurrency(subtotal * 0.20) ||  formatCurrency(basketData.tip - .01) === formatCurrency(subtotal * 0.20)) ? " selected" : ""
                }`}
              onClick={(e) => {
                setPercentTip(0.20);
              }}
            >
              <div className="tip-option-text">
                <span className="percent">20%</span>
                <span className="ml-2 details">
                  {formatCurrency(subtotal * 0.20)}
                </span>
              </div>
            </div>
            <div
              className={`tip-option d-flex flex-column justify-content-center align-items-center mb-sm-4${!!basketData && basketData.tip !== 0 && 
                (formatCurrency(basketData.tip) === formatCurrency(subtotal * 0.25) || formatCurrency(basketData.tip - .01) === formatCurrency(subtotal * 0.25)) ? " selected" : ""
                }`}
              onClick={(e) => {
                setPercentTip(0.25);
              }}
            >
              <div className="tip-option-text">
                <span className="percent">25%</span>
                <span className="ml-2 details">
                  {formatCurrency(subtotal * 0.25)}
                </span>
              </div>
            </div>
            <div className="tip-option d-flex flex-column checkout-field">
              <input
                value={customTip}
                aria-lanel="custom tip"
                onChange={(e) => setCustomTip(e.target.value)}
                placeholder={"Custom Tip"}
              />
              <span className='dollar-span'>$</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const totalBlock = (_) => {
    return (
      <div className="row justify-content-center mb-md-4 mb-sm-0">
        <div className="section d-flex flex-column col-md-8 col-sm-8 m-4 bg-white">
          <div className="d-flex flex-row line-item-group pl-4 pr-4">
            <div className="d-flex flex-column p-0 col-12">
              <div className="line-item d-flex flex-row pt-4 pb-4 justify-content-between">
                <div className="line-item-label d-flex flex-column">
                  Subtotal
                </div>
                <div className="line-item-value d-flex flex-column">
                  {formatCurrency(subtotal)}
                </div>
              </div>
              {deliveryCharge > 0 && (
                <div className="line-item d-flex flex-row pt-4 pb-4 justify-content-between">
                  <div className="line-item-label d-flex flex-column">
                    Delivery Charge
                  </div>
                  <div className="line-item-value d-flex flex-column">
                    {formatCurrency(deliveryCharge)}
                  </div>
                </div>
              )}
              {serviceFee > 0 && (
                <div className="line-item d-flex flex-row pt-4 pb-4 justify-content-between">
                  <div
                    className="line-item-label d-inline-block"
                    ref={target}
                    onMouseEnter={() => setShowInfo(true)}
                    onMouseLeave={() => setShowInfo(false)}
                  >
                    Service Fee {" "}
                    <img src={infoIcon} className="club-info-icon" alt="info icon" />
                    <Overlay target={target.current} show={showInfo} placement="bottom">
                      {({ placement, arrowProps, show: _show, popper, ...otherProps }) => (
                        <div
                          {...otherProps}
                          style={{
                            backgroundColor: "#6f6c67",
                            border: "1px solid white",
                            color: "white",
                            fontFamily: "Oswald",
                            letterSpacing: "1px",
                            opacity: .9,
                            padding: '4px 12px',
                            borderRadius: 3,
                            marginLeft: 7,
                            zIndex: 100,
                            fontWeight: 200,
                            ...otherProps.style,
                          }}
                        >
                          This {formatCurrency(serviceFee)} service fee helps us operate our online ordering service.
                        </div>
                      )}
                    </Overlay>
                  </div>
                  <div className="line-item-value d-flex flex-column">
                    {formatCurrency(serviceFee)}
                  </div>
                </div>
              )}
              <div className="line-item d-flex flex-row pt-4 pb-4 justify-content-between">
                <div className="line-item-label d-flex flex-column">Tax</div>
                <div className="line-item-value d-flex flex-column">
                  {formatCurrency(tax)}
                </div>
              </div>
              {basketData.allowstip && !selectedPayInStore && (
                <div className="line-item d-flex flex-row pt-4 pb-4 justify-content-between">
                  <div className="line-item-label d-flex flex-column">Tip</div>
                  <div className="line-item-value d-flex flex-column">
                    {formatCurrency(basketData?.tip.toFixed(2))}
                  </div>
                </div>
              )}
               {discount > 0 && (
                <div className="line-item d-flex flex-row pt-4 pb-4 justify-content-between">
                  <div className="line-item-label d-flex flex-column">
                    {isMember && "Member "} Discount
                  </div>
                  <div className="line-item-value d-flex flex-column">
                    {`-${formatCurrency(discount)}`}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="line-item-footer d-flex flex-row justify-content-between p-4">
            <div className="line-item-value d-flex flex-column">TOTAL</div>
            <div className="line-item-value d-flex flex-column">
              {formatCurrency(getTotal())}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const errorModal = (error) => {
    const isTransactionError =
      error.includes("x-") ||
      error.includes("billing") ||
      error.toLowerCase().includes("transaction");
    return (
      <AnimatePresence>
        <motion.div
          className="menu-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div
            onClick={closeError}
            id="touch-layer"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          />
          <div
            className="menu-overlay-inner"
            style={{ maxHeight: "40vh", minWidth: "40%", borderRadius: "3px" }}
          >
            <div className="scroll-container full-height">
              <div className="d-flex flex-column w-100">
                <div className="d-flex flex-row m-4 justify-content-center">
                  <div className="d-flex flex-column col-6 align-items-center">
                    <div className="error-title">Error</div>
                  </div>
                </div>
                <div className="d-flex flex-row m-4 justify-content-center">
                  <div className="d-flex flex-column col-10 align-items-center">
                    <div className="details" style={{ textAlign: "center" }}>
                      {error}

                      {memberStatus === "UTP" && isTransactionError && (
                        <div className="transaction-error-container">
                          Sorry but we have a problem processing your card. Please input a different card for this order. If you have any questions, call Member &#38; Guest Services at&nbsp;
                          <a href="tel:7082155674">
                            (708) 215-5674
                          </a>
                        </div>

                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row m-4 justify-content-center">
                  <div className="d-flex flex-column col-6">
                    <button className="save-btn" onClick={closeError}>
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    );
  };
  const loader = (_) => (
    <div className="loader-wrapper is-active">

      <Loader
        type="ThreeDots"
        color="#a47f51aa"
      />

    </div>
  );

  return (
    <>
      <div className="checkout-container d-flex flex-column w-100">
        <Header
          onBagClick={goToBag}
          isLoggedIn={loggedIn}
          onLogin={loginAsMember}
        />
        <div className="scroll-container">
          {basketData && (
            <div className="container-fluid container">
              <div className="header d-flex flex-row flex-wrap">
                <div className="d-flex flex-column col-md-2 col-sm-12 justify-content-center">
                  <div
                    className="media"
                    style={{ cursor: "pointer" }}
                    onClick={goToBag}
                  >
                    <ThemedBackArrow
                      className="d-flex align-self-center mr-3"
                      width="15"
                    />
                    <div className="media-body align-self-center link">
                      Back to my bag
                    </div>
                  </div>
                </div>

                <h2 className="page-title d-flex col-md-10 col-sm-12 mt-md-4 mt-0 mb-md-4 mb-0 justify-content-md-start">
                  Checkout
                </h2>
              </div>

              {personalInfoBlock()}
              {restaurantDetailsBlock()}
              {handoffMode === "dispatch" && deliveryAddressBlock()}
              {handoffMode === "curbside" && pickupDetailsBlock()}
              {timeBlock()}

              {appConstants.couponsEnabled && couponBlock()}

              {paymentBlock()}

              {orderItemsBlock()}

              {basketData.allowstip && !selectedPayInStore && tipBlock()}

              {totalBlock()}

              <div style={{ marginBottom: 30 }}>
                <div className={`reCaptchaContainer ${reCaptchaError && "reCaptchaContainerError"}`}>
                  <ReCAPTCHA
                    sitekey={appConstants.reCaptchaSiteKeyOLO}
                    ref={reCaptchaRef}
                    theme="light"
                    onChange={(value) => {
                      if (value) {
                        setReCaptchaError("");
                      }
                      setReCaptchaValue(value);
                    }}
                  />
                </div>
                {reCaptchaError && (
                  <div className="reCaptchaErrorMessage">
                    {reCaptchaError}
                  </div>
                )}
              </div>

              <div className="row justify-content-center mb-2">
                <button
                  className={`save-btn col-8 ${((!personalInfoFilled &&
                    !personalInfoValid) || !reCaptchaValue) &&
                    "disable"}`}
                  onClick={() => {
                    setIsSubmitted(true);
                    placeOrder();
                    return;
                  }}
                >
                  PLACE ORDER
                </button>
              </div>
              {
                hasAlcohol ?
                  <div className="row justify-content-center mb-md-4 mb-sm-0">
                    <div className="d-flex flex-column col-md-8 col-sm-8 m-4">
                      <span className="agreement">
                        I certify that by placing this order, which includes alcohol, I am 21+ years of age.  By submitting this order, I am also confirming that I have read and agreed to the
                        <a
                          href="https://chwinery.com/privacy-policy"
                          target="_blank"
                          className="footer-item footerCheckout"
                        >
                          <span>Privacy Policy</span>
                        </a>
                        {" "}and
                        <a
                          href="https://chwinery.com/user-agreement"
                          target="_blank"
                          className="footer-item footerCheckout"
                        >
                          <span>Terms of Use</span>
                        </a>.
                      </span>
                    </div>
                  </div> :
                  <div className="row justify-content-center mb-md-4 mb-sm-0">
                    <div className="d-flex flex-column col-md-8 col-sm-8 m-4">
                      <span className="agreement">
                        By submitting this order, I am confirming that I have read and agreed to the <br />
                        <a
                          href="https://chwinery.com/privacy-policy"
                          target="_blank"
                          className="footer-item footerCheckout"
                        >
                          <span>Privacy Policy</span>
                        </a>
                        {" "}and
                        <a
                          href="https://chwinery.com/user-agreement"
                          target="_blank"
                          className="footer-item footerCheckout"
                        >
                          <span>Terms of Use</span>
                        </a>.
                      </span>
                    </div>
                  </div>
              }
            </div>
          )}
          {(basketLoading || orderLoading) && loader()}
          {orderError && errorModal(orderError)}
          {basketError && errorModal(basketError)}
        </div>
        <Footer />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const {
    restaurant,
    basket,
    order,
    settings,
    authentication,
    member,
    paymentForm,
    menu
  } = state;
  const restaurantData = get(restaurant, "data", {});
  const attrbutes = get(restaurantData, "attributes", []);
  const isSymphony = attrbutes.includes('isSimphony');
  const memberData = get(member, "data", null);
  const contacts = get(member, "contacts", null);
  const selectedMember = get(member, "selectedMember", null);
  const basketData = get(basket, "data", {});
  const orderData = get(order, "data", {});
  const orderLoading = get(order, "loading", false);
  const orderError = get(order, "error", null);
  const orderPlaced = get(order, "placed", null);
  const basketLoading = get(basket, "loading", false);
  const basketSaved = get(basket, "saved");
  const basketError = get(basket, "error");
  const dispatchPhoneNumber = get(basket, "dispatchPhoneNumber");
  const billingSchemes = get(order, "billingSchemes", []);
  const hoursData = get(restaurantData, "hours", []);
  const loggedIn = get(authentication, "loggedIn", false);
  const discountApplied = get(basket, "couponApplied", false);
  const discount = get(basket, "data.discount", 0);
  const bottlesAvailable = get(memberData, "openOnlineBottles", 0);
  const cartItems = get(basketData, "products", []);
  const savedPayments = appConstants.cardOnFileEnabled ? get(paymentForm, "savedPayments") : null;
  const bluepayUrl = get(paymentForm, "bluepayUrl");
  const bluepayFormLoading = get(paymentForm, "bluepayFormLoading", false);
  const savedPaymentsError = get(paymentForm, "savedPaymentError", "");
  const savedPaymentsLoading = get(paymentForm, "savedPaymentsLoading", false);
  const loadingNewCard = get(paymentForm, "loadingNewCard", false);
  const convertedBottlesCount = cartItems.filter(cartItem => cartItem.customdata?.startsWith("conversion:") ?? false)
    .map(item => item.quantity)
    .reduce((a, b) => a + b, 0);

  let { categories } = get(menu, "data", {}) || {};
  const menuReducer = (prev, curr) => {
    return prev.concat(curr.products);
  };

  const menuProducts = categories ? categories.reduce(menuReducer, []) : [];
  const hasAlcohol = cartItems.some(cartItem => cartItem.customdata.includes("isWine:") ||
    menuProducts.some(product => product.id === cartItem.productId && product.metadata && product.metadata.some(metadata => metadata.key === "isAlcohol" && metadata.value === "1")));

  return {
    isSymphony,
    discount,
    discountApplied,
    dispatchPhoneNumber,
    memberData,
    restaurantData,
    basketData,
    basketLoading,
    basketSaved,
    basketError,
    billingSchemes,
    orderData,
    orderLoading,
    orderError,
    orderPlaced,
    hoursData,
    settings,
    loggedIn,
    bottlesAvailable,
    convertedBottlesCount,
    savedPayments,
    savedPaymentsError,
    savedPaymentsLoading,
    bluepayUrl,
    bluepayFormLoading,
    contacts,
    loadingNewCard,
    selectedMember,
    hasAlcohol,
  };
}

const connectedApp = withRouter(connect(mapStateToProps)(Checkout));
export { connectedApp as Checkout };
