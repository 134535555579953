import React, { useState } from "react";
import { connect } from "react-redux";
import { memberActions } from "../../_actions";
import get from "lodash/get";
import size from "lodash/size";
import "./styles.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { motion } from "framer-motion";
import { ReactComponent as BottleImage } from "../../assets/images/winebottle.svg";
import UpIcon from "../../assets/images/icon-up.png";
import starIcon from "../../assets/images/gold-star.png";
import { useWindowDimensions } from "../../_helpers";
import { Link } from "react-router-dom";
import infoGold from "../../assets/images/info-gold.png";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { appConstants } from "../../_constants";

const MemberBanner = ({
  status,
  dispatch,
  bottlesAvailable,
  nextRewardPoints,
  firstName,
  memberNumber,
  membershipType,
  seeLess,
  loggedIn,
  convertedBottlesCount,
  bottleLevel
}) => {
  const isPotential = membershipType === "Potential";
  const isMember = loggedIn && !isPotential;
 
  // loggedIn = true;
  // const isPotential = false;
  // const isMember = true;
  // status = "Active";
  // // status = "New";
  // // status = "UTP";
  // // status = "Vacation Hold";
  //  status = "Cancel";
  // // status = "Inactive";
  // // status = "Expired";
  // // status = "To be Activated";

  const showBottleInfo = isMember && status !== "To be Activated";

  const { width } = useWindowDimensions();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // TODO: Move to alert actions
  const showStatusModal = (message) => {
    toast(
      <Modal show onHide={() => toast.dismiss()} centered>
        <Modal.Header closeButton bsPrefix="olo-header">
          <Modal.Title bsPrefix="olo-title">Details</Modal.Title>
        </Modal.Header>
        <Modal.Body bsPrefix="olo-body olo-body-small">
          {message && message.toString()}
          <br />
          Please call <a href="tel:7082155674">(708) 215-5674</a> to reactivate and earn points on your order.
        </Modal.Body>
      </Modal>
    ,{
      containerId: 'alert'
    });
  };

  const renderStatus = () => {
    if (isMember && status === "UTP") {
      return (
        <div className="member-detail-wrap member-detail-status">
          <span className="member-detail" style={{ color: "white" }}>
            {width < 700 && (
              <>
                <span>Membership not active.</span>
                <img
                  src={infoGold}
                  alt="gold star icon"
                  className="star-icon info-gold"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    showStatusModal("Your payment method was unable to be processed.");
                    return false;
                  }}
                />
              </>
            )}
            {width >= 700 && (
              <>
                A payment update is required on your Wine Club account, but you can still place an order. To enjoy Member Benefits, update your payment on the profile tab on your account page or by calling&nbsp;
                <a href="tel:7082155674" style={{ color: "white" }}>
                  (708) 215-5674
                </a>.
              </>
            )}
          </span>
        </div>
      );
    }
    if (isMember && status === "Vacation Hold") {
      return (
        <div className="member-detail-wrap member-detail-status">
          <span
            className="member-detail"
            style={{ color: "white", marginTop: 100 }}
          >
            {width < 700 && (
              <>
                <span>Account on Vacation Hold.</span>
                <img
                  src={infoGold}
                  alt="gold star icon"
                  className="star-icon info-gold"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    showStatusModal("Your account is on Vacation Hold.");
                    return false;
                  }}
                />
              </>
            )}
            {width >= 700 && (
              <>
                Your Wine Club account is on Vacation Hold per your request, but you can still place an order. To enjoy Member Benefits, call&nbsp;
                <a href="tel:7082155674" style={{ color: "white" }}>
                  (708) 215-5674
                </a>
                &nbsp;to reactivate.
              </>
            )}
          </span>
        </div>
      );
    }
    if (
      isMember &&
      (status === "Cancel" || status === "Inactive")
    ) {
      return (
        <div className="member-detail-wrap member-detail-status">
          <span className="member-detail" style={{ color: "white" }}>
            {width < 700 && (
              <>
                <span>Membership not active.</span>
                <img
                  src={infoGold}
                  alt="gold star icon"
                  className="star-icon info-gold"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    showStatusModal("Your Membership is currently not active.");
                    return false;
                  }}
                />
              </>
            )}
            {width >= 700 && (
              <>
                Your Wine Club account is currently not active, but you can still place an order. 
                To enjoy Member Benefits, rejoin on the profile tab on your account page or by calling&nbsp;
                <a href="tel:7082155674" style={{ color: "white" }}>
                  (708) 215-5674
                </a>.
              </>
            )}
          </span>
        </div>
      );
    }
    if (isMember && status === "Expired") {
      return (
        <div className="member-detail-wrap member-detail-status">
          <span className="member-detail" style={{ color: "white" }}>
            {width < 700 && (
              <>
                <span>Membership not active.</span>
                <img
                  src={infoGold}
                  alt="gold star icon"
                  className="star-icon info-gold"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    showStatusModal("Your Membership is currently not active.");
                    return false;
                  }}
                />
              </>
            )}
            {width >= 700 && (
              <>
                Your Wine Club account is currently not active, but you can still place an order.
                To enjoy Member Benefits, call&nbsp;
                <a href="tel:7082155674" style={{ color: "white" }}>
                  (708) 215-5674
                </a>
                &nbsp;to rejoin.
              </>
            )}
          </span>
        </div>
      )};
    if (!loggedIn || isPotential) {
      return (
        <div className="no-login-message pt-1 pb-1">
          {!loggedIn && (
            <>
            <img src={starIcon} className="star-icon" alt="star icon" />
            &nbsp;&nbsp;
            </>
          )}
          {width < 700
            ? "Members save 10% on orders"
            : "Wine Club Members save 10% on carryout and enjoy other benefits."}
          &nbsp;
          <a
            style={{
              textDecoration: "underline",
              color: "white",
            }}
            href="#/wine-club"
          >
            Learn More
          </a>
        </div>
      );
    }
    if (bottleLevel >= 3) {
      return (
        <div className="member-detail-wrap">
          <span className="member-detail" style={{ color: "white" }}>
            {width < 700 && (
              <>
                Visit our Tasting Room to redeem your lux upgrade.
              </>
            )}
            {width >= 700 && (
              <>
                Lux Upgrade for 3-Bottle Members can only be redeemed at our Tasting Rooms at this time.
              </>
            )}
          </span>
        </div>
      );
    }
  };

  return (
    <div
      onClick={(e) => {
        if (e.target.nodeName !== "A") {
          e.preventDefault();
        }
        dispatch(width <= 1000 ? memberActions.toggleMemberBanner() : () => {});
        return false;
      }}
      initial={false}
      className="member-banner-container"
      style={{
        padding: "5px 10px",
      }}
    >
      <Row>
        <Col lg={12} sm className="wrap-top">
          {loggedIn && (
            <div className="member-detail-wrap welcome-back-container">
              {firstName && (
                <div className="welcome-back pt-1 pr-1">
                  Welcome back,{" "}
                  <Link
                    to="/profile-olo"
                    style={{
                      color: "white",
                      textDecorationLine: "underline",
                    }}
                  >
                    {firstName}
                  </Link>
                  !
                </div>
              )}
            </div>
          )}
          {showBottleInfo && (
            <div className="member-detail-wrap">
              <div className="member-detail">
                <span className="member-detail-medium">MEMBER NUMBER: </span>

                <span className="member-detail-heavy">{memberNumber}</span>
              </div>
            </div>
          )}
          {isMember &&
            status !== "To be Activated" &&
            status !== "Cancel" &&
            status !== "Inactive" &&
            status !== "Expired" &&
            status !== "Vacation Hold" && (
              <div className="member-detail-wrap">
                <div className="member-detail">
                  POINTS TO NEXT REWARD:{" "}
                  <span className="member-detail-heavy">
                    {nextRewardPoints}
                  </span>
                </div>
              </div>
            )}

          {renderStatus()}

          {showBottleInfo && (
            <div
              className="seeless-container"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(memberActions.toggleMemberBanner());
                return false;
              }}
            >
              {width > 1000 && (
                <span
                  className="member-detail-medium"
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  {seeLess ? "See Less" : "See More"}
                </span>
              )}

              <motion.img
                src={UpIcon}
                alt="up icon"
                id="up-icon"
                className={`see-less-icon`}
                transition={{ duration: 0.2 }}
                animate={{
                  rotate: seeLess ? [180, 0] : [0, 180],
                }}
              />
            </div>
          )}
        </Col>
      </Row>
      <motion.div
        transition={{ duration: 0.1 }}
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: seeLess && showBottleInfo ? 60 : 0,
          opacity: seeLess && showBottleInfo ? 1 : 0,
          visibility: seeLess && showBottleInfo ? "visible" : "hidden",
        }}
      >
        <Row>
          <Col sm={12} lg={2}>
            <div
              className="member-loyalty-wrap"
              onClick={(e) => {
                dispatch(memberActions.getMember());
                e.preventDefault();
                setShow(true);
                return false;
              }}
            >
              <BottleImage />
              <div className="member-loyalty-container">
                <div className="member-loyalty-text">
                  <span className="member-loyalty-heavy">
                    {bottlesAvailable}
                  </span>
                  <span>
                    AVAILABLE BOTTLE{bottlesAvailable !== 1 ? "S" : ""}
                  </span>
                </div>
                {convertedBottlesCount !== 0 && (
                  <span className="member-loyality-regular">
                    {convertedBottlesCount} bottle
                    {convertedBottlesCount !== 1 ? "s" : ""} in cart
                  </span>
                )}
              </div>
            </div>
          </Col>
          <Col sm={12} lg className="flex-end">
            <a
              href={`${appConstants.memberUrl}/main`}
              target="_blank"
              style={{
                textDecoration: "underline",
                bottom: width < 700 ? 15 : 0,
                right: width < 700 ? 15 : 0,
                position: width < 700 ? "absolute" : "",
              }}
              className="action-link"
            >
              View Details
            </a>
          </Col>
        </Row>
      </motion.div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className="member-bottles-header" closeButton style={{color: "white", backgroundColor: "black"}}>
          <Modal.Title className="member-bottles-title">
            How to use available bottles
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="member-bottles-body" style={{color: "white", backgroundColor: "black"}}>
          You can add Wine Club bottles to your Carryout order. Click the 
          “Use my Wine Club bottles” button on the wines added to the cart. 
          You will see the price reduced to $0.00 or to the upcharge for 
          premium wines.
        </Modal.Body>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  const { member, rewards, basket } = state;
  const { loggedIn } = state.authentication;

  const memberData = get(member, "data", {});
  const rewardData = get(rewards, "data", {});
  const firstName = get(memberData, "firstName", "Member");
  const memberNumber = get(memberData, "memberNumber", "");
  const pointsTotal = get(memberData, "activePoints", 0);
  const bottlesAvailable = get(memberData, "openOnlineBottles", 0);
  const bottleLevel = get(memberData, "bottleLevel", 1);
  const nextRewardPoints = 350 - (pointsTotal % 350);
  const currentRewards = get(rewardData, "activeMemberRewards", []);
  const rewardsAvailable = size(currentRewards, 0);
  const seeLess = get(member, "seeLess", true);
  const membershipType = get(memberData, "membershipType", "Marketing");
  const status = get(memberData, "status", "Active");
  const basketData = get(basket, "data", {});
  const cartItems = get(basketData, "products", []);
  const convertedBottlesCount = cartItems
    .filter(
      (cartItem) => cartItem.customdata?.startsWith("conversion:") ?? false
    )
    .map((item) => item.quantity)
    .reduce((a, b) => a + b, 0);

  return {
    firstName,
    memberNumber,
    membershipType,
    pointsTotal,
    bottlesAvailable,
    rewardsAvailable,
    nextRewardPoints,
    seeLess,
    loggedIn,
    status,
    convertedBottlesCount,
    bottleLevel
  };
}

const connectedMemberBanner = connect(mapStateToProps)(MemberBanner);

export { connectedMemberBanner as MemberBanner };
