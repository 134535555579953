import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useWindowDimensions, usePrevious, getImageUrl } from "../../_helpers";
import { motion, AnimatePresence } from "framer-motion";
import { Qty } from "../../components";
import { get, isEmpty } from "lodash";
import { productsActions, basketsActions, alertActions } from "../../_actions";
import closeIcon from "../../assets/images/close@2x.png";
import "./styles.css";
import { withRouter } from "react-router-dom";
import { PairingItem } from "../../components";
import ThemedButton from "../ThemedButton";

const MenuItemDetails = ({
  basketData,
  detailMode,
  dispatch,
  imagePath,
  loading,
  optionGroups,
  onDismiss,
  product,
  menuData,
  productImages,
  rec,
  inst,
  restaurantData,
  isCurrentUserMember,
  history,
}) => {
  const { width } = useWindowDimensions();
  const specialInstructionsMaxLength = get(
    restaurantData,
    "specialinstructionsmaxlength",
    20
  );
  const productName = get(product, "name", "");
  let productDescription = get(product, "description", "");
  const [qty, setQty] = useState(1);
  const customdata = get(product, "customdata", []);
  const [basketTotal, setBasketTotal] = useState(0);
  const [bagTotal, setBagTotal] = useState(0);
  const [options, setOptions] = useState("");
  const [optionsObjects, setOptionsObjects] = useState([]);
  const [instructions, setInstructions] = useState("");
  const [recipient, setRecipient] = useState("");
  const loaded = useRef(false);
  const mods = useRef([]);
  const deetsScrollContainerRef = useRef(null);
  const metadata = get(product, "metadata", []);
  let pairingObject;
  let metaObject;
  const [isWine, setIsWine] = useState(get(metaObject, "isWine", "0"));
  const [isSweet, setIsSweet] = useState(get(metaObject, "isSweet", "0"));

  useEffect(() => {
    // on first load if item is top level single option and mandatory, select it by default
    if(loaded.current){
    optionGroups.forEach(group => {
      group.options.forEach(option => {
        if(group.mandatory && !option.selected && group.options.length===1){
          selectOption(
            group.id,
            option.id,
            option.cost,
            option.selected
          );
          setBasketTotal(parseFloat(option.cost))
          setBagTotal(parseFloat(option.cost));
        }
      })
    })
  }
  }, [loaded.current])

  const isMemberExclusive = !!(
    product.metadata &&
    product.metadata.find(field =>
      field.key === 'memberExclusive' &&
      field.value === '1'
    ));


  if (metadata && menuData && menuData.categories) {
    metaObject = metadata.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }),
      {}
    );
    menuData.categories.forEach((category) => {
      category.products.forEach((product) => {
        if (product.chainproductid === parseInt(metaObject.pairingId)) {
          pairingObject = product;
        }
      });
    });
  }

  if (detailMode === "edit" && menuData && menuData.categories) {
    menuData.categories.forEach((category) => {
      const currentProduct = category.products.find(
        (p) => p.id === product.productId
      );
      if (currentProduct) {
        const metadata = get(currentProduct, "metadata", []) ?? [];
        const metaObject = metadata.reduce(
          (obj, item) => Object.assign(obj, { [item.key]: item.value }),
          {}
        );
        productDescription = currentProduct.description;
        menuData.categories.forEach((category) => {
          category.products.forEach((product) => {
            if (product.chainproductid === parseInt(metaObject.pairingId)) {
              pairingObject = product;
            }
          });
        });
      }
    });
  }

  const [imageUrl, setImageUrl] = useState(
    isEmpty(productImages) ? null : `${imagePath}${productImages[2].filename}`
  );

  const isComplimentary = customdata?.includes("complimentary");

  useEffect(() => {
    if (metaObject && detailMode !== "edit") {
      setIsWine(get(metaObject, "isWine", "0"));
      setIsSweet(get(metaObject, "isSweet", "0"));
    }
  }, [metaObject]);

  // if editing, load up product info on load
  useEffect(() => {
    if (detailMode === "edit") {
      if (product && product.customdata) {
        setIsWine(product.customdata.includes("isWine") ? "1" : "0");
      }
      setBagTotal(parseFloat(get(product, "totalcost", 0)));
      setBasketTotal(
        parseFloat(get(product, "totalcost", 0) / product.quantity)
      );
    }

    if (detailMode === "edit" && optionGroups) {
      mods.current = [];
      setInstructions(inst);
      setRecipient(rec);

      setQty(product.quantity);
      const optionsArray = [];

      if (product.choices) {
        const selectedIds = product.choices.map((choice) => choice.optionid);
        selectedIds.forEach((id) => {
          optionGroups.forEach(group => {
            group.options.forEach(option => {
              if(option.id === id){
                selectOption(group.id, option.id, option.cost, option.selected);
              } 
              if (option.children) {
                option.modifiers.forEach(mod => {
                 mod.options.forEach(op => {
                  if(op.id === id){
                    selectExtraMod(mod, op);
                  }
                 })
                })
              }
            })
          })
      })
    }
      setOptions(optionsArray.toString());
    }
  }, [loaded.current]);

  const oldProducts = usePrevious(basketData.products);
  useEffect(() => {
    // check that addedProduct is not wine_pairing before dismissing modal
    if (loaded.current && basketData.products && oldProducts) {
      //basket updated, hide modal
      const result = basketData.products.filter(
        ({ id: id1 }) => !oldProducts.some(({ id: id2 }) => id2 === id1)
      );
      if (
        (result &&
          result[0] &&
          result[0].specialinstructions !== "WINE_PAIRING") ||
        detailMode === "edit"
      )
        onDismiss();
    } else {
      loaded.current = true;
    }
  }, [basketData]);

  const changeQty = (num) => {
    if (qty + num > 0) setQty(qty + num);
  };

  useEffect(() => {
    setBagTotal(basketTotal * qty);
  }, [basketTotal, qty]);

  const loadHiRes = () => {
    setImageUrl(
      isEmpty(productImages) ? null : `${imagePath}${getImageUrl(productImages, 'mobile-webapp-menu')}`
    );
  };

  const selectOption = (groupId, optionId, optionCost, selected) => {
    if (selected) {
      setBasketTotal(basketTotal - parseFloat(optionCost));
    } else {
      const currentGroup = optionGroups.find((group) => group.id === groupId);
      if (
        currentGroup?.mandatory ||
        (!currentGroup?.mandatory && currentGroup?.maxselects === "1")
      ) {
        
        const currentOption = currentGroup.options.find(
          (option) => option.selected
        );
        setBasketTotal(
          basketTotal -
          parseFloat(
            currentOption && currentOption.cost ? currentOption.cost : 0
          ) +
          parseFloat(optionCost)
        );
      } else {
        setBasketTotal(basketTotal + parseFloat(optionCost));
      }
    }
   dispatch(productsActions.selectModifier(groupId, optionId));
  };

  const selectExtraMod = (group, option) => {
    if(mods.current.includes(option.id.toString())){
      mods.current = mods.current.filter(id => id !== option.id.toString())
    } else {
      mods.current.push(option.id.toString());
    }
    setBasketTotal(
      basketTotal + parseFloat(mods.current.includes(option.id.toString()) ? option.cost : -option.cost)
    );
    dispatch(productsActions.selectModifier(group.id, option.id));
  };


  const addToBasket = () => {
    const memberExclusiveMetadata = product.metadata
      ? product.metadata.find((x) => x.key === "memberExclusive")
      : null;

    const isMemberExlusive =
      memberExclusiveMetadata && String(memberExclusiveMetadata.value) === "1";

    if (!isCurrentUserMember && isMemberExlusive) {
      dispatch(alertActions.memberExclusiveError(history));
      return;
    }

    const guid = get(basketData, "id", "");


    dispatch(
      detailMode === "edit"
        ? basketsActions.updateProduct(
          guid,
          product.id,
          product.productId,
          qty,
          `${options},${mods.current}`,
          instructions,
          recipient,
          customdata,
          undefined,
          product.quantity,
        )
        : basketsActions.addProducts(
          guid,
          product.id,
          product.name,
          qty,
          `${options},${mods.current}`,
          instructions,
          recipient,
          isWine === "1"
            ? `isWine:${product.chainproductid}${isSweet === "1" ? ",isSweet" : ""
            }`
            : ""
        )
    );
  };

  //generate comma delimited string to pass as options
  useEffect(() => {
    const optionsArray = [];
    const optionsObjectArray = [];
    for (let i = 0; i < optionGroups.length; i++) {
      for (let j = 0; j < optionGroups[i].options.length; j++) {
        if (optionGroups[i].options[j].selected) {
          optionsArray.push(optionGroups[i].options[j].id);
          optionsObjectArray.push(optionGroups[i].options[j]);
        }
      }
    }
    setOptionsObjects(optionsObjectArray);
    setOptions(optionsArray.toString());
  }, [optionGroups]);

  useEffect(() => {
    if (detailMode !== "edit") {
      setBasketTotal(basketTotal + parseFloat(get(product, "cost", 0)));
      setBagTotal(parseFloat(get(product, "cost", 0) / qty));
    }
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        className="menu-overlay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{ zIndex: 100 }}
      >
        <div
          onClick={onDismiss}
          id="touch-layer"
          style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
        />
        <div className="menu-overlay-inner">
          <motion.img
            src={closeIcon}
            alt="close button icon"
            className="close-btn"
            onClick={onDismiss}
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.9 }}
          />
          <div className="deets-scroll-container" ref={deetsScrollContainerRef}>
            {imageUrl ? (
              <img
                key={imageUrl}
                onLoad={() => loadHiRes()}
                src={imageUrl}
                alt={productName}
                className="product-img"
              />
            ) : (
              <div className="product-no-img" />
            )}
            <div className="inner-wrap">
              {isWine === "1" ? (
                <div className="overlay-title-wine">{productName}</div>
              ) : (
                <div className="overlay-title">{productName}</div>
              )}
              {isWine === "1" ? (
                <div className="overlay-description-wine">
                  {productDescription}
                </div>
              ) : (
                <div className="overlay-description">{productDescription}</div>
              )}
              {isWine === "1" && !isMemberExclusive && !isCurrentUserMember && (
                <div className="join-message">
                  Wine Club Members save 10%.{" "}
                  <a
                    onClick={() => { document.location.href = "#/wine-club"; onDismiss() }}
                    className="join-now">
                    Join now
                  </a>
                </div>
              )}
              {isWine === "1" && isMemberExclusive && !isCurrentUserMember && (
                <div className="join-message">
                  Join the Cooper's Hawk Wine Club to gain access to this bottle and to save 10% on your carryout order today.
                </div>
              )}
              {!!product.availability && !!product.availability.description && (
                <div className="item-availability-description">{`(Available ${product.availability.description
                  })`}</div>
              )}
              {pairingObject && (
                <>
                  <div className="divider" />
                  <div className="overlay-subtitle horiz caps">
                    Suggested Pairings:
                  </div>
                  <PairingItem
                    product={pairingObject}
                    imagePath={imagePath}
                    foodPairName={productName}
                  />
                  <div className="divider" />
                </>
              )}
              {!loading &&
                optionGroups.map((group) => {
                  return (
                    <div key={group.id}>
                      <div className="overlay-subtitle horiz caps">
                        {group.description}{" "}
                        {group.mandatory && (
                          <div className="required">Required</div>
                        )}
                      </div>
                      <div className="option-group">
                        {!loading &&
                          group.options.map((option) => (
                            <motion.div
                              key={option.id}
                              className={
                                option.selected
                                  ? "option-selected col-md-6 col-xs-12"
                                  : "option col-md-6 col-xs-12"
                              }
                              whileHover={{
                                scale: 1.02,
                                transition: { duration: 0.1 },
                              }}
                              whileTap={{ scale: 0.98 }}
                              onClick={group.mandatory && group.options.length===1 ? null : () => {
                                selectOption(
                                  group.id,
                                  option.id,
                                  option.cost,
                                  option.selected
                                );
                              }}
                            >
                              <div
                                className={
                                  option.selected
                                    ? "option-text-selected"
                                    : "option-text"
                                }
                              >
                                {option.name}
                              </div>
                              {option.cost > 0 && (
                                <div
                                  className={
                                    option.selected ? "cost-selected" : "cost"
                                  }
                                >
                                  + ${option.cost.toFixed(2)}
                                </div>
                              )}
                            </motion.div>
                          ))}
                        <div className="divider" />
                      </div>
                    </div>
                  );
                })}
              {optionsObjects &&
                optionsObjects
                  .filter((object) => object.children)
                  .map((option) =>
                    option.modifiers.map((group) => (
                      <div key={group.id}>
                        <div className="overlay-subtitle horiz caps">
                          {group.description}{" "}
                          {group.mandatory && (
                            <div className="required">Required</div>
                          )}
                        </div>
                        <div className="option-group">
                          {!loading &&
                            group.options.map((option) => (
                              <motion.div
                                key={option.id}
                                className={
                                  mods.current.includes(option.id.toString())
                                    ? "option-selected col-md-6 col-xs-12"
                                    : "option col-md-6 col-xs-12"
                                }
                                whileHover={{
                                  scale: 1.02,
                                  transition: { duration: 0.1 },
                                }}
                                whileTap={{ scale: 0.98 }}
                                onClick={() => {
                                  selectExtraMod(group, option);
                                }}
                              >
                                <div
                                  className={
                                    mods.current.includes(option.id.toString())
                                      ? "option-text-selected"
                                      : "option-text"
                                  }
                                >
                                  {option.name}
                                </div>
                                {option.cost > 0 && (
                                  <div
                                    className={
                                      option.selected ? "cost-selected" : "cost"
                                    }
                                  >
                                    + ${option.cost.toFixed(2)}
                                  </div>
                                )}
                              </motion.div>
                            ))}
                          <div className="divider" />
                        </div>
                      </div>
                    ))
                  )}
              {!(isWine === "1") && (
                <>
                  <div className="overlay-subtitle horiz caps" for="special-instructions">
                    Special Requests (max {specialInstructionsMaxLength}{" "}
                    characters):
                  </div>
                  <input
                    value={instructions}
                    id="special-instructions"
                    onChange={(e) => setInstructions(e.target.value)}
                    className="request-field"
                    placeholder="Type any special requests here"
                    maxLength={specialInstructionsMaxLength}
                    onFocus={(event) => {
                      const { target } = event;
                      setTimeout(() => {
                        deetsScrollContainerRef.current.scrollTop +=
                          target.getBoundingClientRect().top - 10;
                      }, 300);
                    }}
                  />
                  <div className="divider" />
                  <div className="overlay-subtitle horiz caps" for="recipient-diner-name">
                    Diner's Name:
                  </div>
                  <input
                    value={recipient}
                    id="recipient-diner-name"
                    onChange={(e) => setRecipient(e.target.value)}
                    className="request-field diner"
                    placeholder="Who will be receiving this item?"
                    onFocus={(event) => {
                      const { target } = event;
                      setTimeout(() => {
                        deetsScrollContainerRef.current.scrollTop +=
                          target.getBoundingClientRect().top - 10;
                      }, 300);
                    }}
                  />
                </>
              )}
            </div>
          </div>

          {(!isMemberExclusive || isCurrentUserMember) && (
            <div className="add-container">
              {isComplimentary ? (
                <Qty qty={-1} />
              ) : (
                <Qty
                  onIncrement={() => changeQty(1)}
                  onDecrement={() => changeQty(-1)}
                  qty={qty}
                />
              )}
              <motion.div
                className="add-btn"
                whileHover={{
                  scale: 1.01,
                  transition: { duration: 0.1 },
                }}
                whileTap={{ scale: 1 }}
                onClick={addToBasket}
              >
                <ThemedButton className="add-btn-txt">
                  {detailMode === "edit"
                    ? `UPDATE BAG $${bagTotal.toFixed(2)}`
                    : `ADD TO BAG $${bagTotal.toFixed(2)}`}
                </ThemedButton>
              </motion.div>
            </div>
          )}

          {isMemberExclusive && !isCurrentUserMember && (
            <div className="add-container">
              <div style={{ flex: 1 }}></div>
              <div
                className="add-btn"
                style={{ minWidth: 200, flex: 0, maxHeight: 60 }}
                onClick={() => { onDismiss(); document.location.href = "#/wine-club" }}
              >
                <ThemedButton className="add-btn-txt">
                  JOIN THE WINE CLUB
                </ThemedButton>
              </div>
            </div>
          )}

        </div>
      </motion.div>
    </AnimatePresence>
  );
};

function mapStateToProps(state, ownProps) {
  const { menu, modifiers, basket, restaurant, member } = state;
  const { loggedIn } = state.authentication;

  const memberData = get(member, "data", {});
  const membershipType = get(memberData, "membershipType", "Marketing");
  const isPotential = membershipType === "Potential";
  const isCurrentUserMember = loggedIn && !isPotential;

  const basketData = get(basket, "data", {});
  const menuData = get(menu, "data", {});
  const loading = get(menu, "loading", false);
  const modifiersData = get(modifiers, "data", {});
  const optionGroups = get(modifiersData, "optiongroups", []);
  let { imagepath } = menuData;
  const recipient = get(ownProps.product, "recipient", "");
  const instructions = get(ownProps.product, "specialinstructions", "");
  let productImages = get(ownProps.product, "images", []);
  const restaurantData = get(restaurant, "data", {});
  const attrbutes = get(restaurantData, "attributes", []);
  if (ownProps.detailMode === "edit") {
    const productId = ownProps.product.productId;
    menuData.categories.forEach((category) => {
      category.products.forEach((product) => {
        if (product.id === productId) {
          productImages = get(product, "images", []);
        }
      });
    });
  }

  return {
    imagePath: imagepath,
    optionGroups,
    loading,
    menuData,
    basketData,
    productImages,
    rec: recipient,
    inst: instructions,
    restaurantData,
    isCurrentUserMember,
  };
}


const connectedMenuItemDetails = withRouter(
  connect(mapStateToProps)(MenuItemDetails)
);
export { connectedMenuItemDetails as MenuItemDetails };

MenuItemDetails.propTypes = {
  onDismiss: PropTypes.func,
  onQtyDown: PropTypes.func,
  onQtyUp: PropTypes.func,
  qty: PropTypes.number,
  detailMode: PropTypes.oneOf(["add", "edit"]),
};

MenuItemDetails.defaultProps = {
  detailMode: "add",
  onDismiss: () => { },
  onQtyDown: () => { },
  onQtyUp: () => { },
  qty: 1,
};
