import { appConstants } from "../_constants";
import { addAuthorization } from "../_helpers";

export const Orders = {
  validateOrder,
  submitOrder,
  submitOrderByProxy,
  refreshOrderByGuid,
  refreshOrderByRef,
  refreshDeliveryStatus,
  getBillingSchemes,
  pickupOrder,
};

async function pickupOrder(orderGuid, data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${appConstants.oloApiUrl}/orders/${orderGuid}/arrival`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        throw new Error("Unexpected error");
      }
      const { code, message, num } = result;
      throw new Error(message);
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}


async function validateOrder(basketGuid) {
  const requestOptions = {
    method: "POST",
  };

  return fetch(
    `${appConstants.oloApiUrl}/baskets/${basketGuid}/validate`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        throw new Error("Unexpected error during basket validation");
      }
      const { code, message, num } = result;
      throw new Error(message);
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function submitOrder(basketGuid, data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  addAuthorization(requestOptions.headers);

  return fetch(
    `${appConstants.oloApiUrl}/baskets/${basketGuid}/submit`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        throw new Error(e);
      }
      const { code, message, num } = result;
      throw new Error(message);
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function submitOrderByProxy(basketGuid, data) {
  const {
    token,
    ...safeData
  } = data;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({spreedly_token: token, ...safeData}),
  };

  addAuthorization(requestOptions.headers);

  return fetch(
    `${appConstants.oloApiUrl}/baskets/${basketGuid}/submit`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        // Couldn't parse the JSON
        //throw new Error(response.status);
        throw new Error(e);
      }
      throw new Error(result.because);
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function refreshOrderByGuid(guid) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${appConstants.oloApiUrl}/orders/${guid}`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result = await response.json();
      const { message } = result;
      // Got valid JSON with error response, use it
      throw new Error(message || response.status);
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function refreshOrderByRef(ref) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${appConstants.oloApiUrl}/orders/byref/${ref}`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result = await response.json();
      const { message } = result;
      // Got valid JSON with error response, use it
      throw new Error(message || response.status);
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function refreshDeliveryStatus(guid) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${appConstants.oloApiUrl}/orders/${guid}/deliverystatus`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result = await response.json();
      const { message } = result;
      // Got valid JSON with error response, use it
      throw new Error(message || response.status);
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}

async function getBillingSchemes(guid) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${appConstants.oloApiUrl}/baskets/${guid}/billingschemes`,
    requestOptions
  ).then(async (response) => {
    if (!response.ok) {
      let result = await response.json();
      const { message } = result;
      // Got valid JSON with error response, use it
      throw new Error(message || response.status);
    }
    // Successful response, parse the JSON and return the data
    return response.json();
  });
}