import React, { useEffect } from "react";
import { Route, withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import flow from "lodash/flow";
import { Menu } from "./pages/Menu";
import { Locator } from "./pages/Locator";
import { Checkout } from "./pages/Checkout";
import { Checkin } from "./pages/Checkin";
import { LoginOlo } from "./pages/LoginOlo";
import { Confirmation } from "./pages/Confirmation";
import { ForgotPasswordOlo } from "./pages/ForgotPasswordOlo";
import { NewPasswordOlo } from "./pages/NewPasswordOlo";
import { ProfileOlo } from "./pages/ProfileOlo";
import MetaTags from "react-meta-tags";
import { ParticipatingLocations } from "./pages/ParticipatingLocations";
import { UserAgreement } from "./pages/UserAgreement";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { ContactUs } from "./pages/ContactUs";
import { OnlineTrackingOptOutGuide } from "./pages/OnlineTrackingOptOutGuide";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import 'react-toastify/dist/ReactToastify.css';
import "./indexOLO.scoped.scss";
import withCookie from "./_helpers/withCookie";
import withBagTimeout from './_helpers/withBagTimeout';
import withRefreshToken from './_helpers/withRefreshToken';
import { WineClubModal } from "./components/WineClubModal";
import { Private } from "./pages/Private";

const AppOLO = ({ basket, dispatch }) => {
  let history = useHistory();
  return [
    <div className="app-container">
      <MetaTags>
        <meta
          name="viewport"
          content="width=device-width, maximum-scale=1.0, minimum-scale=1.0, initial-scale=1.0"
        />
      </MetaTags>
      <Route exact path="/" component={Locator} />
      <Route exact path="/menu/:locationSlug" component={Menu} />
      <Route exact path="/checkout/:basketGuid" component={Checkout} />
      <Route exact path="/confirmation/:basketGuid" component={Confirmation} />
      <Route exact path="/order/arrival/:orderGuid" component={Checkin} />
      <Route exact path="/login-olo" component={LoginOlo} />
      <Route exact path="/forgotpassword-olo" component={ForgotPasswordOlo} />
      <Route exact path="/newpassword-olo" component={NewPasswordOlo} />
      <Route exact path="/profile-olo" component={ProfileOlo} />
      <Route
        exact
        path="/participating-locations"
        component={ParticipatingLocations}
      />
      <Route exact path="/user-agreement" component={UserAgreement} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route
        exact
        path="/online-tracking-opt-out-guide"
        component={OnlineTrackingOptOutGuide}
      />
      <Route exact path="/Private" component={Private} />
      <ToastContainer
        hideProgressBar
        enableMultiContainer
        containerId="toastNotification"
      />
      <ToastContainer
        hideProgressBar
        enableMultiContainer
        containerId="toastWarning"
      />
      <ToastContainer
        hideProgressBar
        enableMultiContainer
        containerId="alert"
        className="hide-toastify"
      />
    </div>,
    <WineClubModal forgotPass={() => history.push('/forgotpassword-olo')}/>
  ];
};

function mapStateToProps(state) {
  const { restaurant, basket } = state;
  return {
    restaurant,
    basket
  };
}

const connectedApp = flow([
  connect(mapStateToProps),
  withRouter,
  withCookie,
  withRefreshToken,
  withBagTimeout,
])(AppOLO);

export { connectedApp as AppOLO };
