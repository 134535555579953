import React, {useState} from "react";
import "./styles.scoped.scss";
import "./styles.scss";
import {ButtonAnimator} from '../../../components';
import {ModalContainer} from "../ModalContainer";
import headerImage from "../../../assets/images/wineclub-header2.png";
import loyaltyIcon from "../../../assets/images/wc-icon-loyalty.png";
import exclusiveIcon from "../../../assets/images/wc-icon-exclusive.png";
import discountIcon from "../../../assets/images/wc-icon-discount.png";
import pickupIcon from "../../../assets/images/icon-pickup.png";
import tastingsIcon from "../../../assets/images/wc-icon-tastings.png";
import birthdayIcon from "../../../assets/images/wc-icon-birthday.png";
import arrowDown from "../../../assets/images/carat-down.svg";
import arrowUp from "../../../assets/images/carat-up.svg";
import Accordion from "react-bootstrap/Accordion";
import {Col, Row} from "react-bootstrap";

const renderAccordionItem = ({id, activeId, icon, title, description}) => {
  return (
    <Col xs={12}>
      <div className={activeId === id ? 'panel-wrap active-panel' : 'panel-wrap'}>
        <div className="panel-header clearfix">
          <Accordion.Toggle className="panel-toggle" variant="link" eventKey={id}>
            <img src={icon} className="accordion-icon" alt="accordion icon" /><div style={{maxWidth:'80%', display: 'inline-block'}}>{title}</div>
            <img src={activeId === id ? arrowUp : arrowDown} className="accordion-arrow" alt="accordion arrow icon" />
          </Accordion.Toggle>
        </div>

        <Accordion.Collapse eventKey={id}>
          <div className="panel-body">{description}</div>
        </Accordion.Collapse>
      </div>
    </Col>
  );
};

const accordionItems = [
  {
    id: '1',
    title: "EXCLUSIVE MONTHLY WINES",
    description: "Discover a new, handcrafted wine every month, or swap for other applicable wines in our wine portfolio.",
    icon: exclusiveIcon,
    iconStyle: { width: 22, marginRight: 10 },
  },
  {
    id: '2',
    title: "10% OFF CARRYOUT",
    description: "Enjoy 10% discount and earn points on all carryout orders.",
    icon: pickupIcon,
    iconStyle: { width: 20, marginRight: 12 },
  },
  {
    id: '3',
    title: "DINING & BIRTHDAY REWARDS",
    description: "Enjoy a $15 birthday reward every year, and earn points towards dining rewards (350 points = $25 reward).",
    icon: loyaltyIcon,
    iconStyle: { width: 22, marginRight: 10 },
  },
  {
    id: '4',
    title: "10-20% OFF WINES",
    description: "Enjoy discounts and earn points on retail wine purchases: 10% off (1-5 bottles), 15% off (6-11 bottles), and 20% off (12+ bottles) in our restaurant.",
    icon: discountIcon,
    iconStyle: { width: 20, marginRight: 12 },
  },
  {
    id: '5',
    title: "COMPLIMENTARY WINE TASTINGS (2-Bottle Option)",
    description: "Enjoy a monthly guided “wine tasting for two” in our Tasting Room (a $24 value).",
    icon: tastingsIcon,
    iconStyle: { width: 22, marginRight: 10, marginBottom: 4 },
  },
];

const WineClubHome = ({history}) => {
  const [activeId, setActiveId] = useState('0');

  const toggleAccordion = id => {
    if (id && id !== activeId) {
      setActiveId(id);
    } else {
      setActiveId('0');
    }
    return false;
  };

  return (
    <div className="wine-club-modal-home">
      <ModalContainer>
        <div className="header-text">COOPER'S HAWK WINE CLUB</div>
        <div className="description-text">Our Wine Club is an all-inclusive membership where you'll not only receive our winemaker's newly released wine each month, but also enjoy many great benefits. <strong>Pick up</strong> or enjoy your bottle at any Cooper's Hawk location!</div>
        <div className="margin-container" style={{paddingBottom:100}}>
        <Accordion defaultActiveKey={activeId} activeKey={activeId} onSelect={toggleAccordion}>
          <Row align="center">
            {accordionItems.map(item => (
                renderAccordionItem({activeId, ...item})
            ))}
          </Row>
        </Accordion>
        </div>
        <div className="button-floater">
          <ButtonAnimator>
            <div className="submit" onClick={() => history.push('/club-selection')}>JOIN NOW</div>
          </ButtonAnimator>
        </div>
      </ModalContainer>
    </div>
  );
};

export { WineClubHome };
