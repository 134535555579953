import React from "react";
import { Bag } from "../../components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { motion } from "framer-motion";
import FullLogo from "../../assets/images/logo-full.png";
import EsquireLogo from '../../assets/images/esquire-logo-white.svg'
import EsquirePattern from '../../assets/images/esquire-header-pattern.svg'
import {  isThemeEsquire, useWindowDimensions,  } from "../../_helpers";
import "./styles.scoped.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";
import { alertActions, memberActions } from "../../_actions";

const Header = ({
  onBagClick,
  hideLogin,
  isLoggedIn,
  onLogin,
  hideBag = false,
  history,
  qty,
  lastSlug,
  dispatch,
  disableHome = false,
}) => {
  const { width } = useWindowDimensions();

  //ToDo: Replace this with a redux store value
  const isEsquire = isThemeEsquire();


  const onLogout = () => dispatch(
    alertActions.oloLogout(
      () => dispatch(memberActions.logout())
    )
  );

  return (
    <Row 
    className="wrap"
    style={{backgroundImage: `url(${isEsquire ? EsquirePattern : ''})`}}
    >
      <Col xs={1} lg={3}>
        <img
          onClick={() => {
            if(disableHome) return;
            if (qty === 0) {
              history.push("/");
            } else {
              if (lastSlug) {
                history.push(`/menu/${lastSlug}`);
              }
            }
          }}
          src={isEsquire ? EsquireLogo : FullLogo}
          className={`d-md-block ${width < 375 ? 'logo-xsmall' :  width < 480 ? 'logo-small' : 'logo'}`}
          alt="coopers hawk logo"
        />
      </Col>
      <Col />
      {!hideLogin && (
        <Row className="sign-in" onClick={isLoggedIn ? onLogout : onLogin}>
          <motion.div
            whileHover={{
              scale: 1.04,
              transition: { duration: 0.1 },
            }}
            whileTap={{ scale: 0.98 }}
          >
            {isLoggedIn ? "Logout" : "Login"}
          </motion.div>
        </Row>
      )}
      {!hideBag && <Bag className="gold" onBagClick={onBagClick} />}
    </Row>
  );
};

function mapStateToProps(state) {
  const { basket, restaurantSelected } = state;
  const basketData = get(basket, "data", {});
  const lastSlug = get(restaurantSelected, "data.slug", "");

  if (basketData && basketData.products) {
    let qty = 0;
    basketData.products.forEach((element) => {
      if (element.customdata !== "complimentary") {
        qty += element.quantity;
      }
    });
    return { qty, lastSlug };
  }
  return { qty: 0, lastSlug };
}

export default withRouter(connect(mapStateToProps)(Header));
