import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PropTypes from "prop-types";
import "./styles.scoped.scss";
import Blank from "../../assets/images/blank.png";
import ThemedGoldPlus from "../ThemedGoldPlus"

import MedalAward from "../../assets/images/award@2x.png";

import { connect } from "react-redux";
import get from "lodash/get";
import { alertActions } from "../../_actions";
import { withRouter } from "react-router-dom";
import {getImageUrl} from "../../_helpers";

const MenuItem = ({
  product,
  imagePath,
  onSelect,
  onQuick,
  isCurrentUserMember,
  confirmationDisplay,
  style,
  freeCost,
  showAdd
}) => {
  const images = product.images;
  const imgSrc = images && `${imagePath}${getImageUrl(images, 'mobile-webapp-menu')}`;
  const cost = product.cost.toFixed(2);
  const bannerTextMetadata =
    product.metadata && product.metadata.find((x) => x.key === "bannerText");
  const hasBanner = bannerTextMetadata;

  const memberExclusiveMetadata = product.metadata
    ? product.metadata.find((x) => x.key === "memberExclusive")
    : null;

  const isMemberExlusive =
    memberExclusiveMetadata && String(memberExclusiveMetadata.value) === "1";

    const comboMetadata = product.metadata
    ? product.metadata.find((x) => x.key === "isCombo")
    : null;

  const isCombo =
  comboMetadata && String(comboMetadata.value) === "1";

  return (
    <Col
      xs={confirmationDisplay ? 10 : 12}
      sm={confirmationDisplay ? 10 : 6}
      md={confirmationDisplay ? 10 : 6}
      lg={confirmationDisplay ? 8 : 4}
      xl={confirmationDisplay ? 6 : 3}
      key={product.id}
      style={{ padding: ".75%", ...style }}
    >
      <div className="menu-item" onClick={() => onSelect(product)}>
        <img src={imgSrc || Blank} className="menu-item-image" alt={`${product.name}`} />

        {hasBanner && (
          <div className="menu-item-banner">
            <div className="medal-award-container">
              <img src={MedalAward} className="medal-award" alt="metal award" />
            </div>
            <div className="menu-item-banner-text">
              {bannerTextMetadata.value}
            </div>
            <div className="menu-item-banner-right" />
          </div>
        )}
        {!imgSrc && <div className="menu-item-image-placeholder" />}
        <div className="menu-item-description-container">
          <div className="flex-container">
            <div className="item-name">{product.name}</div>
            {!isCombo && 
            <div className="item-cost">
              <div className={freeCost != null ? "strikethru" : ""}>
                ${cost}
              </div>
              {freeCost != null && <div>${freeCost.toFixed(2)}</div>}
            </div>}
          </div>
          <div className="flex-container description-container">
            <div className="menu-item-availability-container">
              <div className="item-description">{product.description}</div>
              {!!product.availability.description && (
                <div className="item-availability-description">{`(Available ${
                  product.availability.description
                })`}</div>
              )}
            </div>
            <div className="plus-container" />
          </div>
        </div>
        <div
          className="plus-container"
          style={{ position: "absolute", bottom: 0, right: 0 }}
        >
          {showAdd && (
            <ThemedGoldPlus
              width={35}
              onClick={(e) => {
                e.stopPropagation();
                if (!isCurrentUserMember && isMemberExlusive) {
                  onSelect(product);
                } else {
                  onQuick(product);
                }
              }}
            />
          )}
        </div>
      </div>
    </Col>
  );
};

function mapStateToProps(state) {
  const { member } = state;
  const { loggedIn } = state.authentication;

  const memberData = get(member, "data", {});
  const membershipType = get(memberData, "membershipType", "Marketing");
  const isPotential = membershipType === "Potential";
  const isCurrentUserMember = loggedIn && !isPotential;

  return {
    isCurrentUserMember
  };
}

export default withRouter(connect(mapStateToProps)(MenuItem));

MenuItem.propTypes = {
  product: PropTypes.object,
  imagePath: PropTypes.string,
  onSelect: PropTypes.func,
  confirmationDisplay: PropTypes.bool,
  style: PropTypes.object,
  freeCost: PropTypes.number,
  showAdd: PropTypes.bool
};

MenuItem.defaultProps = {
  product: {},
  imagePath: "",
  onSelect: () => {},
  confirmationDisplay: false,
  style: {},
  freeCost: null,
  showAdd: true
};
