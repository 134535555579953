import React, { useEffect, useState } from "react";
import onClickOutside from "react-onclickoutside";
import { DropDownSelector } from "../../components";
import { restaurantsActions } from "../../_actions";
import { connect } from "react-redux";
import { find, get, isEmpty } from "lodash";
import "./styles.css";

const moment = require("moment");

const OLO_FORMAT = "YYYYMMDD HH:mm";
const DATE_LABEL_FORMAT = "MMM Do";
const DATE_VALUE_FORMAT = "YYYYMMDD";
const TIME_LABEL_FORMAT = "hh:mm a";
const LEAD_TIME = 30;


const hoursToOptions = (days, earliestReadyTime, businessHours, todayM, showASAPTime) =>
  days.map((day) => {
    const { start, end } = day;
    let openM = moment(start, OLO_FORMAT);
    let closeM = moment(end, OLO_FORMAT);
    const isToday = openM.isSame(todayM, "date");

    // Don't show times that are before or after business hours
    // Diana 08/25/2021 
    // businessHours contains the list of start and end times (including the weekday) for all the loaded days. 
    // businessHourEntry is considered the first entry that matches the weekday. This is incorrect when more than 7 days are loaded
    // instead we need to look for the date we are planning to order for
    try {
      const dayDatePart = openM.format(DATE_VALUE_FORMAT);
      const businessHourEntry = businessHours && businessHours.ranges.find(entry => moment(entry.start, OLO_FORMAT).format(DATE_VALUE_FORMAT) === dayDatePart);
      const businessStart = businessHourEntry && businessHourEntry.start;
      const businessEnd = businessHourEntry && businessHourEntry.end;

      if (businessStart) {
        const businessStartM = moment(businessStart, OLO_FORMAT);
        if (!isToday) {
          businessStartM.add(LEAD_TIME, "minutes");
        }
        if (openM.isBefore(businessStartM, "minutes")) {
          openM = businessStartM;
        }
      }
      if (businessEnd) {
        const businessEndM = moment(businessEnd, OLO_FORMAT);
        if (closeM.isAfter(businessEndM, "minutes")) {
          closeM = businessEndM;
        }
      }
    } catch (e) {
      console.log("Caught", e);
    }

    const isBusinessOpen = openM.isSameOrBefore(todayM);
    const timeOptions = [];

    if (!earliestReadyTime) {
      earliestReadyTime = todayM;
    }
    const startM = isToday ? moment(earliestReadyTime, OLO_FORMAT) : openM;

    while (startM.minutes() % 15 !== 0) {
      // find closest multiple of 15 (e.g. 12:15, 12:30)
      startM.add(1, "minutes");
    }
    // Diana added 05/07/2021
    // We weren't considering the situation in which the earliestReadyTime was the day after today. 
    // Before we hadled this, in this case (earliestReadyTime is the day after today), 
    // the date dropdown will have today as date and the time dropdown will have the ready time of tomorrow (e.g. 9:00AM), which is wrong. 
    // The new if handles this case
    const earliestReadyTimeM = moment(earliestReadyTime, OLO_FORMAT);
    if (isToday && isBusinessOpen && todayM.isSame(earliestReadyTimeM, 'day')) {
      timeOptions.push({
        label: `ASAP ${showASAPTime ? ` (${earliestReadyTimeM.format("h:mm a")})` : ''}`,
        value: "asap",
      });
    }

    if (
      startM.isSameOrAfter(moment(earliestReadyTime, OLO_FORMAT), "minute") &&
      (!isToday || (startM.isSameOrAfter(moment().add(30, "minutes")) && startM.isSame(moment(), "date")))
    ) {
      timeOptions.push({
        label: startM.format(TIME_LABEL_FORMAT),
        value: startM.format(OLO_FORMAT),
      });
    }

    closeM.subtract(15, "minutes");

    while (startM < closeM) {
      startM.add(15, "minutes");
      if (isToday && moment(earliestReadyTime, OLO_FORMAT).isAfter(closeM, "minute")) {
        continue;
      }
      timeOptions.push({
        label: startM.format(TIME_LABEL_FORMAT),
        value: startM.format(OLO_FORMAT),
      });
    }
    
    // Diana added 05/07/2021 
    // Here we were adding a new record (ASAP) in the time dropdown. 
    // In the case in which the reastaurant is close to closing time and the earliestReadyTime is tomorrow, 
    // my understanding is that this ASAP record shouldn't be in the time dropdown
    // if (!timeOptions || timeOptions.length === 0) {
    //   timeOptions.push({
    //     label: "ASAP",
    //     value: "asap",
    //   });
    // }

    return {
      label: `${isToday ? "Today, " : ""}${openM.format(DATE_LABEL_FORMAT)}`,
      value: openM.format(DATE_VALUE_FORMAT),
      timeOptions,
    };
  }).filter(option => {
    // Diana added 05/07/2021 
    // Filter will remove the date options which don't have any time options.
    // In the situation in which the restaurant won't be able to deliver in the same day, 
    // the date is removed from the dropdown (because the day is not valid for delivery)
    return option.timeOptions && option.timeOptions.length
  });

const DateTimeSelector = ({
  dispatch,
  selected,
  hours,
  onChange,
  containerClass = "",
  earliestReadyTime,
  businessHours,
  timezone,
  showASAPTime,
  restaurantData,
  basketData,
  loading
}) => {
  const [dateSelectorOpen, setDateSelectorOpen] = useState(false);
  const [timeSelectorOpen, setTimeSelectorOpen] = useState(false);
  let todayM = moment();
  if (timezone) {
    todayM.utcOffset(timezone);
    todayM = moment(todayM.format(OLO_FORMAT), OLO_FORMAT)
  }

  const isAsapMode = selected === "asap";
  const dateOptions = hoursToOptions(hours, earliestReadyTime, businessHours, todayM, showASAPTime);

  const selectedM = !isAsapMode ? moment(selected, OLO_FORMAT) : null;
  let selectedDateOption = isAsapMode
    ? find(dateOptions, { value: todayM.format(DATE_VALUE_FORMAT) })
    : find(dateOptions, { value: selectedM.format(DATE_VALUE_FORMAT) });

  if(!selectedDateOption) {
    selectedDateOption = dateOptions[0];
  }

  const timeOptions = get(selectedDateOption, "timeOptions", []);
  let selectedTimeOption = isAsapMode
    ? find(timeOptions, { value: "asap" })
    : find(timeOptions, { value: selectedM.format(OLO_FORMAT) });

  if(!selectedTimeOption && timeOptions) {
    selectedTimeOption = timeOptions[0];
  }

  const closeAll = (_) => {
    setDateSelectorOpen(false);
    setTimeSelectorOpen(false);
  };

  DateTimeSelector.handleClickOutside = closeAll;
  

  useEffect(() => {
    const restaurantId = get(restaurantData, 'id', null) || get(basketData, "vendorid", null);
    if(restaurantId) {
    dispatch(restaurantsActions.getHours(restaurantId));
    }
  }, []);
  
  useEffect(() => {
    if (timeOptions && timeOptions[0] && timeOptions[0].value !== 'asap' && selected === 'asap') {
      onChange(timeOptions[0].value);
    }
  }, [timeOptions]);


  // Diana 06/10/2021
  // "timewanted" is updating but it not sending the new value to the parent
  // We check if we have a time from the parent (selected) and if we have a selectedTimeOption and we check if they are different.
  // If they are different we update the selected for the parent with onChange
  useEffect(() => {
    if(selected && selected !== "asap" && selectedTimeOption && selectedTimeOption.value !== "asap" && selected !== selectedTimeOption.value) {
      onChange(selectedTimeOption.value);
    }
  }, [selected, selectedTimeOption]);

  return (
    <div className={`datetime-field-container ${containerClass} d-flex flex-column flex-sm-row`}>
      {timeOptions.length > 0 && (
        <>
          <DropDownSelector
            value={selectedDateOption.value}
            items={dateOptions}
            onClick={(_) => setDateSelectorOpen(true)}
            onChange={(item) => {
              const itemOption = find(dateOptions, { value: item });
              const refTime = isAsapMode
                ? todayM
                : moment(selectedTimeOption.value, OLO_FORMAT);
              const isToday = moment(item, DATE_VALUE_FORMAT).isSame(todayM, "day");
              let newSelectedTime = isToday ? find(itemOption.timeOptions, (option) => {
                let optionM = moment(option.value, OLO_FORMAT);
                return optionM.hour() >= refTime.hour();
              }) : null;
              if (!newSelectedTime) {
                newSelectedTime = itemOption.timeOptions[0];
              }
              onChange(newSelectedTime ? newSelectedTime.value : "asap");
              closeAll();
            }}
            isOpen={dateSelectorOpen}
          />
          <div style={{width:30}}/>
          {loading ? <div className="p-0 col-12 col-sm-6 mt-2 mt-sm-0 mx-sm-2 spacer"><div class="loader"></div></div> :
          <DropDownSelector
            value={selectedTimeOption.value}
            items={timeOptions}
            onClick={(_) => setTimeSelectorOpen(true)}
            onChange={(item) => {
              onChange(item);
              closeAll();
            }}
            isOpen={timeSelectorOpen}
          />}
        </>
      )}
    </div>
  );
};

DateTimeSelector.prototype = {};

const clickOutsideConfig = {
  handleClickOutside: () => DateTimeSelector.handleClickOutside,
};

function mapStateToProps(state) {
  const { restaurants, restaurantSelected, member, basket } = state;
  const memberData = get(member, "data", {});
  const basketData = get(basket, "data", {});
  const lastLocation = get(memberData, "lastLocation", {});
  const lastSlug = get(lastLocation, "CHW_OLO_Slug__c", "");
  const restaurantSelectedData = get(restaurantSelected, "data", {});
  const homeLocation =
    restaurants.data &&
    restaurants.data.restaurants &&
    restaurants.data.restaurants.find(
      (restaurant) => restaurant.slug === lastSlug
    );
  const restaurantData = isEmpty(restaurantSelectedData)
    ? homeLocation
    : restaurantSelectedData;
   
  return {
    restaurantData,
    basketData
  };
}

const connectedDateTimeSelector = connect(mapStateToProps)(
  onClickOutside(DateTimeSelector, clickOutsideConfig)
);
export { connectedDateTimeSelector as DateTimeSelector };
