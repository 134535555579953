import React from "react";
import Row from "react-bootstrap/Row";
import "./styles.scoped.css";
import packageInfo from "../../../package.json";
import { useWindowDimensions } from "../../_helpers";

const Footer = ({ style }) => {
  const { width } = useWindowDimensions();
  return (
    <Row className="footer" style={style}>
      <a
        href="https://chwinery.com/user-agreement"
        target="_blank"
        className="footer-item"
      >
        <span>Terms of Use</span>
      </a>
      <a
        href="https://chwinery.com/privacy-policy"
        target="_blank"
        className="footer-item"
      >
        <span>Privacy Policy</span>
      </a>
      <a
        href="https://chwinery.com/contact/contact-us"
        target="_blank"
        className="footer-item"
      >
        <span> {width < 480 ? "Contact" : "Contact Us"}</span>
      </a>
      {process.env.NODE_ENV === 'development' && <span className="version">v {packageInfo.version}</span>}
    </Row>
  );
};

export default Footer;
