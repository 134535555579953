import React, { useState, useEffect, useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  basketsActions,
  memberActions,
  ordersActions,
  newMemberActions,
} from '../../_actions';
import infoIcon from '../../assets/images/info-16.png';
import { Header } from '../../components';
import { get, isEmpty } from 'lodash';
import './styles.scoped.scss';
import ThemedBackArrow from '../../components/ThemedBackArrow';
import { useRenameDocument } from '../../context/renameDocument';

const moment = require('moment');

const formatCurrency = num => `$${num.toFixed(2)}`;

const getMessageByStatusAndHandoffMode = (status, handoff) => {
  switch (status) {
    case 'In Progress':
      return 'Your order is in progress!';
    case 'Scheduled':
      return 'Your order has been scheduled!';
    case 'Transmitting':
      return 'Your order is being sent to the restaurant...';
    case 'Canceled':
      return 'Your order has been canceled';
    case 'Completed':
      return handoff === 'dispatch'
        ? 'Your order has been delivered!'
        : 'Your order has been picked up!';
    default:
      return '';
  }
};

const Confirmation = ({
  basketData,
  memberData,
  restaurantData,
  orderData,
  orderContactInfo,
  orderRefreshed,
  deliveries,
  loggedIn,
  dispatch,
  history,
}) => {
  let { basketGuid } = useParams();

  useRenameDocument('Order Confirmation');

  const orderNumber = get(orderData, 'oloid', '');
  const orderGuid = get(orderData, 'id', '');
  const orderStatus = get(orderData, 'status');
  const handoffMode = get(orderData, 'deliverymode');
  const cartItems = get(orderData, 'products', []);
  const subtotal = get(orderData, 'subtotal', 0);
  const tax = get(orderData, 'salestax', 0);
  const tip = get(orderData, 'tip', 0);
  const billingAccountId = get(orderData, 'billingaccountid', '');
  const timeReady = get(orderData, 'readytime', null);
  const timeReadyText = timeReady
    ? moment(timeReady, 'YYYYMMDD HH:mm').format('h:mm a / MMM DD')
    : 'TBD';
  const contactEmail = get(orderContactInfo, 'emailaddress', '');
  const cardLastFour = get(orderContactInfo, 'cardlastfour', '');
  const payInStore = billingAccountId === 'Cash' || !cardLastFour;
  const activeDelivery = get(deliveries, 0, null);
  const discount = get(orderData, 'discount', 0);
  const serviceFee = get(orderData, 'totalfees', 0);
  const deliveryCharge = get(orderData, 'customerhandoffcharge', 0);
  const total = get(orderData, 'total', 0);
  const isMember =
    loggedIn && memberData && memberData.membershipType !== 'Potential';
  const address = get(orderData, 'deliveryaddress', null);
  const customfields = get(orderData, 'customfields', null);
  const [showInfo, setShowInfo] = useState(false);
  const target = useRef(null);

  const refreshTimer = useRef(null);

  const makeCustomField = customfields?.find(
    customField =>
      customField.label && customField.label.toLowerCase() === 'make',
  );
  const modelCustomField = customfields?.find(
    customField =>
      customField.label && customField.label.toLowerCase() === 'model',
  );
  const colorCustomField = customfields?.find(
    customField =>
      customField.label && customField.label.toLowerCase() === 'color',
  );

  // useEffect(() => {
  //   dispatch(basketsActions.destroyBasket());
  // });

  // useEffect(() => {
  //   refreshTimer.current = setTimeout(() => {
  //     dispatch(ordersActions.refreshOrderByGuid(orderGuid));
  //   }, 30000);
  //   return () => {
  //     clearTimeout(refreshTimer.current);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (orderRefreshed) {
  //     dispatch(ordersActions.reset());
  //     refreshTimer.current = setTimeout(() => {
  //       dispatch(ordersActions.refreshOrderByGuid(orderGuid));
  //     }, 30000);
  //   }
  // }, [orderRefreshed]);

  // const getTotal = (_) => {
  //   return subtotal - discount + serviceFee + tax + tip;
  // };

  const loginAsMember = () => {
    const confirmationURL = `/confirmation/${basketGuid}`;
    dispatch(memberActions.setLoginDestination(confirmationURL));
    history.push('/login-olo?continueAsType=member');
  };

  const deliveryAddress = () => {
    if (!address) {
      return '';
    }
    const { streetaddress, city, zipcode } = address;
    return `${streetaddress}, ${city}, ${zipcode}`;
  };

  const goToCreateAccount = () => {
    dispatch(newMemberActions.setPotentialEmail(contactEmail));
    dispatch(memberActions.setLoginDestination('/'));
    history.push('/login-olo?continueAsType=new');
  };

  const goToCheckin = () => {
    if (orderGuid) history.push(`/order/arrival/${orderGuid}`);
  };

  const pickupBlock = () => {
    return (
      <div className="row justify-content-center mb-md-4 mb-sm-0 pt-5">
        <div className="section d-flex flex-column col-md-8 col-sm-8 m-4 bg-white">
          <div className="section-header d-flex flex-row justify-content-between">
            <div className="section-title flex-md-grow-1 p-4">
              CURBSIDE PICKUP INSTRUCTIONS
            </div>
          </div>
          <div className="section-title d-flex flex-row p-4">CAR DETAILS</div>
          <div className="detail-item d-flex flex-row ml-4 mr-4 justify-content-start">
            <div className="d-flex flex-column details mr-3">
             Make:
            </div>
            <div className="d-flex flex-column section-title">
              {makeCustomField?.value}
            </div>
          </div>
          <div className="detail-item d-flex flex-row ml-4 mr-4 justify-content-start">
            <div className="d-flex flex-column details mr-3">
             Model:
            </div>
            <div className="d-flex flex-column section-title">
              {modelCustomField?.value}
            </div>
          </div>
          <div className="detail-item d-flex flex-row ml-4 mr-4 justify-content-start">
            <div className="d-flex flex-column details mr-3">
              Color:
            </div>
            <div className="d-flex flex-column section-title">
              {colorCustomField?.value}
            </div>
          </div>
         
          <div className="guest-banner d-flex flex-row justify-content-center mt-4">
            When you arrive, please let us know you're here so the restaurant
            knows to bring your order.
          </div>
          <div className="d-flex flex-row mt-4">
            <div className="col-4" />
            <button onClick={goToCheckin} className="create-account-btn col-4">
              I'M HERE!
            </button>
          </div>
          <div className="d-flex flex-row justify-content-center mt-2 mb-4">
            <div className="page-title">Thank You!</div>
          </div>
     
        </div>
      </div>
    );
  };

  const orderDetailsBlock = _ => {
    return (
      <div className="row justify-content-center mb-5">
        <div className="section d-flex flex-column col-md-8 col-sm-10 ml-3 mr-3 p-0 bg-white">
          <div className="section-header d-flex flex-row justify-content-between">
            <div className="section-title p-4">ORDER NUMBER</div>
            <div className="details p-4">{orderNumber}</div>
          </div>

          <div className="detail-item d-flex flex-row ml-4 mr-4 justify-content-start">
            <div className="d-flex flex-column details mr-3">
              {`${
                handoffMode === 'dispatch'
                  ? 'Estimated Delivery'
                  : 'Estimated ready'
              }:`}
            </div>
            <div className="d-flex flex-column section-title">
              {timeReadyText}
            </div>
          </div>

          <div className="detail-item d-flex flex-row ml-4 mr-4 flex-wrap justify-content-start">
            <div className="d-flex flex-column col-auto p-0 mr-2 details mr-3">
              Confirmation sent to:
            </div>
            <div className="d-flex flex-column col-auto p-0 col-sm-auto section-title ">
              {contactEmail}
            </div>
          </div>
          {handoffMode === 'dispatch' && (
            <div className="detail-item d-flex flex-row ml-4 mr-4 justify-content-start">
              <div className="d-flex flex-column details mr-3">
                Delivery address:
              </div>
              <div className="d-flex flex-column section-title">
                {deliveryAddress()}
              </div>
            </div>
          )}
          {address?.specialinstructions && (
            <div className="detail-item d-flex flex-row ml-4 mr-4 flex-wrap justify-content-start">
              <div className="d-flex flex-column details mr-3">
                Delivery Instructions:
              </div>
              <div className="d-flex flex-column col-auto p-0 mr-2 details mr-3">
                {address?.specialinstructions}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const deliveryStatusBlock = _ => {
    return (
      <div className="row justify-content-center mb-5">
        <div className="section d-flex flex-column col-md-8 col-xs-11 p-0 bg-white">
          <div className="section-header d-flex flex-row justify-content-between">
            <div className="section-title p-4">DELIVERY TRACKING</div>
          </div>
          <div className="d-flex flex-row p-0">
            <div className="info-group d-flex flex-column p-4">
              <div className="d-flex flex-row justify-content-start mt-2 mb-2">
                <div className="section-title">
                  {get(activeDelivery, 'drivername')}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start mt-1 mb-1">
                <div className="details">
                  {get(activeDelivery, 'deliveryservice')}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start mt-1 mb-1">
                <a
                  className="details link"
                  href={`tel:${get(activeDelivery, 'driverphonenumber')}`}>
                  Contact Driver
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const restaurantDetailsBlock = _ => {
    if (!restaurantData) {
      return null;
    }
    return (
      <div className="row justify-content-center mb-5">
        <div className="section d-flex flex-column col-md-8 col-sm-10 ml-3 mr-3 p-0 bg-white">
          <div className="section-header d-flex flex-row justify-content-between">
            <div className="section-title p-4">ORDERING FROM</div>
          </div>
          <div className="d-flex flex-row p-0">
            <div className="info-group d-flex flex-column p-4">
              <div className="d-flex flex-row justify-content-start mt-2 mb-2">
                <div className="section-title">
                  COOPER'S HAWK - {restaurantData.name}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start mt-1 mb-1">
                <div className="details">
                  {`${restaurantData.streetaddress} ${restaurantData.city}, ${
                    restaurantData.state
                  }`}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start mt-1 mb-1">
                <a
                  className="details link"
                  href={`tel:${restaurantData.telephone}`}>
                  {restaurantData.telephone}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const orderItemsBlock = _ => {
    return (
      <div className="row justify-content-center mb-5">
        <div className="section d-flex flex-column col-md-8 col-sm-10  ml-3 mr-3 p-0 bg-white">
          <div className="section-header d-flex flex-row justify-content-between">
            <div className="section-title flex-md-grow-1 p-4">YOUR ORDER</div>
          </div>

          <div className="d-flex flex-row p-0">
            <div className="info-group d-flex flex-column col-12 pl-4 pr-4 pt-0 pb-0">
              {cartItems.map(item => (
                <div className="d-flex flex-row order-item">
                  <div className="d-flex flex-column col-1 pb-4 pt-4 pl-0 pr-0 ml-2">
                    <div className="section-title">{item.quantity}</div>
                  </div>
                  <div className="d-flex flex-column col-7 col-sm-8 pb-4 pt-4 p-0">
                    <div className="d-flex flex-row">
                      <div className="section-title">
                        {item.name}&nbsp;&nbsp;
                        {`${
                          !isEmpty(item.recipient) ? `(${item.recipient})` : ''
                        }`}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="details">
                        <span>
                          {item.choices.map((choice, index) => (
                            <span>
                              {choice.cost !== 0 ? (
                                <strong>Add {choice.name}</strong>
                              ) : (
                                choice.name
                              )}

                              {index < item.choices.length - 1 ? ', ' : ''}
                            </span>
                          ))}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column col-4 col-sm-3 pb-4 pt-4"
                    style={{ alignItems: 'flex-end' }}>
                    <div className="section-title">
                      {formatCurrency(item.totalcost)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const totalBlock = _ => {
    return (
      <div className="row justify-content-center mb-5 pb-3">
        <div className="section d-flex flex-column col-md-8 col-sm-10 ml-3 mr-3 p-0 bg-white">
          <div className="d-flex flex-row line-item-group pl-4 pr-4">
            <div className="d-flex flex-column p-0 col-12">
              <div className="line-item d-flex flex-row pt-4 pb-4 justify-content-between">
                <div className="line-item-label d-flex flex-column">
                  Subtotal
                </div>
                <div className="line-item-value d-flex flex-column">
                  {formatCurrency(subtotal)}
                </div>
              </div>
              {deliveryCharge > 0 && (
                <div className="line-item d-flex flex-row pt-4 pb-4 justify-content-between">
                  <div className="line-item-label d-flex flex-column">
                    Delivery Charge
                  </div>
                  <div className="line-item-value d-flex flex-column">
                    {formatCurrency(deliveryCharge)}
                  </div>
                </div>
              )}
              {serviceFee > 0 && (
                <div className="line-item d-flex flex-row pt-4 pb-4 justify-content-between">
                  <div
                    className="line-item-label d-inline-block"
                    ref={target}
                    onMouseEnter={() => setShowInfo(true)}
                    onMouseLeave={() => setShowInfo(false)}>
                    Service Fee{' '}
                    <img
                      src={infoIcon}
                      className="club-info-icon"
                      alt="info icon"
                    />
                    <Overlay
                      target={target.current}
                      show={showInfo}
                      placement="bottom">
                      {({
                        placement,
                        arrowProps,
                        show: _show,
                        popper,
                        ...otherProps
                      }) => (
                        <div
                          {...otherProps}
                          style={{
                            backgroundColor: '#6f6c67',
                            border: '1px solid white',
                            color: 'white',
                            fontFamily: 'Oswald',
                            letterSpacing: '1px',
                            opacity: 0.9,
                            padding: '4px 12px',
                            borderRadius: 3,
                            marginLeft: 7,
                            zIndex: 100,
                            fontWeight: 200,
                            ...otherProps.style,
                          }}>
                          This {formatCurrency(serviceFee)} service fee helps us
                          operate our online ordering service.
                        </div>
                      )}
                    </Overlay>
                  </div>
                  <div className="line-item-value d-flex flex-column">
                    {formatCurrency(serviceFee)}
                  </div>
                </div>
              )}
              <div className="line-item d-flex flex-row pt-4 pb-4 justify-content-between">
                <div className="line-item-label d-flex flex-column">Tax</div>
                <div className="line-item-value d-flex flex-column">
                  {formatCurrency(tax)}
                </div>
              </div>
              {tip > 0 && (
                <div className="line-item d-flex flex-row pt-4 pb-4 justify-content-between">
                  <div className="line-item-label d-flex flex-column">Tip</div>
                  <div className="line-item-value d-flex flex-column">
                    {formatCurrency(tip)}
                  </div>
                </div>
              )}
              {discount > 0 && (
                <div className="line-item d-flex flex-row pt-4 pb-4 justify-content-between">
                  <div className="line-item-label d-flex flex-column">
                    {isMember && 'Member '} Discount
                  </div>
                  <div className="line-item-value d-flex flex-column">
                    {`-${formatCurrency(discount)}`}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="line-item-footer d-flex flex-row justify-content-between p-4">
            <div className="line-item-value d-flex flex-column">
              {payInStore
                ? 'TOTAL TO BE PAID IN STORE'
                : `TOTAL CHARGED TO CARD: ending in ${cardLastFour}`}
            </div>
            <div className="line-item-value d-flex flex-column mr-2">
              {formatCurrency(total)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const guestBlock = _ => {
    return (
      <div className="row justify-content-center mb-5">
        <div className="section d-flex flex-column col-md-8 col-sm-10 ml-3 mr-3 p-4 bg-white">
          <div className="guest-banner d-flex flex-row justify-content-center">
            Do you want to be able to order more quickly in the future?
          </div>
          <div className="guest-banner d-flex flex-row justify-content-center">
            Create an account now
          </div>
          <div className="d-flex flex-row mt-4">
            <button
              onClick={goToCreateAccount}
              className="create-account-btn col-12">
              JOIN NOW
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="confirmation-container d-flex flex-column w-100">
      <Header hideBag={true} isLoggedIn={loggedIn} onLogin={loginAsMember} />
      <div className="scroll-container">
        <div className="container-fluid">
          <div
            className="back-to-my-bag"
            style={{ cursor: 'pointer' }}
            onClick={() => history.replace('/')}>
            <ThemedBackArrow
              className="d-flex align-self-center mr-3"
              width="15"
            />
            <div className="back-to-my-bag-text">Start New Order</div>
          </div>

          <div className="row justify-content-center">
            <div className="page-title d-flex col-md-8 col-sm-12 mt-4 mb-4 justify-content-sm-center justify-content-md-start">
              {getMessageByStatusAndHandoffMode(orderStatus, handoffMode)}
            </div>
          </div>
          {handoffMode === 'curbside' && restaurantData?.supportedarrivalmessagehandoffmodes?.includes('CurbsidePickup') && pickupBlock()}
          {orderDetailsBlock()}
          {activeDelivery && deliveryStatusBlock()}
          {restaurantDetailsBlock()}
          {orderItemsBlock()}
          {totalBlock()}
          
          {!loggedIn && guestBlock()}
          <div
            className="back-to-my-bag-bottom mb-4"
            style={{ cursor: 'pointer' }}
            onClick={() => history.replace('/')}>
            <div className="back-to-my-bag-text">Start New Order</div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { restaurant, order, authentication, member, basket } = state;
  const basketData = get(basket, 'data', null);
  const memberData = get(member, 'data', null);
  const restaurantData = get(restaurant, 'data', {});
  const orderData = get(order, 'data', {});
  const orderContactInfo = get(order, 'contactInfo', {});
  const orderRefreshed = get(order, 'refreshed');
  const deliveries = get(order, 'deliveries');
  const loggedIn = get(authentication, 'loggedIn', false);
  return {
    basketData,
    memberData,
    restaurantData,
    orderData,
    orderContactInfo,
    orderRefreshed,
    deliveries,
    loggedIn,
  };
}

const connectedConfirmation = withRouter(
  connect(mapStateToProps)(Confirmation),
);
export { connectedConfirmation as Confirmation };
