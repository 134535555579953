import React, { useState, useEffect, useReducer, useMemo } from "react";
import { connect } from "react-redux";
import { alertActions, basketsActions, restaurantsActions } from "../../_actions";
import { Link } from "react-router-dom";
import AutoComplete from "react-google-autocomplete";
import { motion, AnimatePresence } from "framer-motion";
import { SegmentedSelector, DateTimeSelector } from "../../components";
import {
  flatten,
  get,
  intersection,
  invert,
  isEmpty,
  find,
  map,
  some,
  values,
} from "lodash";
import { parseAddressComponents, isEsquireSlug, isThemeEsquire } from "../../_helpers";
import AlertIcon from "../../assets/images/icon-circle-exclamation-mark.png";
import InfoIcon from "../../assets/images/icon-info.svg";
import "./styles.scoped.scss";
import { appConstants } from "../../_constants";
import { Baskets } from '../../_services/baskets.service'
const moment = require("moment");

const HANDOFF_TYPE_MAP = {
  0: "pickup",
  1: "curbside",
  2: "dispatch",
};

const OLO_FORMAT = "YYYYMMDD HH:mm";

const HandoffModal = ({
  onDismiss,
  addressComponents,
  restaurantData,
  restaurantLoading,
  deliveryData,
  basketData,
  basketLoading,
  basketSaved,
  basketError,
  menuData,
  hoursData,
  settings,
  dispatch,
  fromSearch = false,
  onSaved,
}) => {
  const basketGuid = get(basketData, "id");
  const earliestreadytime = get(basketData, "earliestreadytime");
  const earliestTimeM = earliestreadytime && moment(earliestreadytime, OLO_FORMAT);
  const selectedItems = get(basketData, "products", []);
  const restaurantId = get(restaurantData, "id", null);
  const handoffPreference = get(settings, "handoff");
  const slug = get(restaurantData, 'slug', '');
  const isEsquire = isEsquireSlug(slug);
  let esquireBorderClass = "";
  


  // UPDATE commented this code to debug https://red-foundry.atlassian.net/browse/CHOCAT-258
  // unclear as to why this code was needed, as removing it appears to have no ill effect but resolves the bug 

  // 02-18-21 GD   ------------------------------------------------------------------------
  // const [fullyLoaded, setFullyLoaded] = useState(false);
  // useEffect(() => {
  //   update();
  // },[]);

  // const update = async () => {
  //   if(handoffPreference==='dispatch') {
  //     //need to set address on basket before being allowed to update handoff to dispatch
  //     // we will set to the store's address on the basket in order to then update handoff
  //     const city = restaurantData.city;
  //     const streetaddress = restaurantData.streetaddress;
  //     const zipcode = restaurantData.zip;
  //     await Baskets.updateDeliveryAddress(basketGuid, streetaddress, city, zipcode);
  //   }
  //   const result = await Baskets.updateHandoffType(basketGuid, handoffPreference);
  //   // after syncing dispatch type, need to refresh basket data
  //   await dispatch(basketsActions.getBasket(basketGuid));
  //   setFullyLoaded(true); // using the fullyLoaded flag for smooth UI updating
  // }
  // ---------------------------------------------------------------------------------------
   
  const initSelectedAddress = fromSearch
    ? addressComponents
    : get(basketData, "deliveryaddress");
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState("");
  const initFormState = {
    selectedTimeMode: get(basketData, "timemode"),
    selectedTimeWanted: get(basketData, "timewanted"),
    selectedAddress: initSelectedAddress,
    earliestReadyTime: get(basketData, "earliestreadytime"),
    dispatchAptNumber:
      get(basketData, "deliveryaddress") !== null
        ? get(basketData, "deliveryaddress.building")
        : "",
    dispatchPhoneNumber:
      get(basketData, "deliveryaddress") !== null
        ? get(basketData, "deliveryaddress.phonenumber")
        : "",
    handoffMode: handoffPreference,
    searchValue: "",
    aptInputVisible: false,
    addressVerified: false,
    saving: false,
    changeset: {
      timeMode: false,
      timeWanted: false,
      deliveryAddress:
        handoffPreference === "dispatch" && initSelectedAddress != null,
      handoffMode: handoffPreference !== get(basketData, "deliverymode"),
    },
  };

  const formReducer = (state, action) => {
    let changeset = state.changeset;
    switch (action.type) {
      case "handoff-selected":
        return {
          ...state,
          handoffMode: action.data,
          changeset: {
            ...changeset,
            handoffMode: true,
            deliveryAddress:
              action.data === "dispatch" && state.selectedAddress,
          },
        };
      case "time-selected":
        if (action.data === "asap") {
          return {
            ...state,
            selectedTimeMode: "asap",
            changeset: {
              ...changeset,
              timeMode: true,
            },
          };
        }
        return {
          ...state,
          selectedTimeMode: "advance",
          selectedTimeWanted: action.data,
          changeset: {
            ...changeset,
            timeWanted: true,
          },
        };
      case "address-selected":
        return {
          ...state,
          selectedAddress: action.data,
          aptInputVisible: true,
          changeset: {
            ...changeset,
            deliveryAddress: true,
          },
        };
      case "address-verified":
        return {
          ...state,
          addressVerified: true,
        };
      case "apt-changed":
        return {
          ...state,
          dispatchAptNumber: action.data,
          changeset: {
            ...changeset,
            deliveryAddress: true,
          },
        };
      case "phone-changed":
        return {
          ...state,
          dispatchPhoneNumber: action.data,
          changeset: {
            ...changeset,
            deliveryAddress: true,
          },
        };
      case "search-changed":
        setError("");
        return {
          ...state,
          searchValue: action.data,
        };
      case "clear-search":
        return {
          ...state,
          searchValue: "",
          selectedAddress: null,
          dispatchAptNumber: "",
          aptInputVisible: false,
        };
      default:
        return state;
    }
  };
  const [formState, formDispatch] = useReducer(formReducer, initFormState);
  const selectedTime = formState.selectedTimeWanted && moment(formState.selectedTimeWanted, OLO_FORMAT);
  const isTodaySelected = selectedTime && selectedTime.isSame(moment(), "date");
  const isEarliestTimeToday = earliestTimeM && earliestTimeM.isSame(moment(), "date");
  const buttonDisabled = !isEarliestTimeToday && (formState.selectedTimeMode === "asap" || isTodaySelected);
  const formHasChanges = (_) => some(values(formState.changeset));


  const resctrictedItemIds = useMemo(() => {
    // commented out locatorPopup per: https://red-foundry.atlassian.net/browse/COOP-70
   // if(formState.handoffMode === "dispatch") dispatch(alertActions.warning(appConstants.locatorPopup, "handoff-warning"));
    // #1: filter down to products having > 0 unavailablehandoffmodes that we support
    const productsWithRestrictions = flatten(
      map(get(menuData, "categories"), (cat) => {
        return cat.products.filter((product) => {
          return (
            intersection(
              values(HANDOFF_TYPE_MAP),
              get(product, "unavailablehandoffmodes", [])
            ).length > 0
          );
        });
      })
    );
    // #2: filter down to products not supported by CURRENTLY SELECTED handoff mode
    return map(
      productsWithRestrictions.filter((product) => {
        return get(product, "unavailablehandoffmodes", []).includes(
          formState.handoffMode
        );
      }),
      "id"
    );
  }, [formState.handoffMode]);

  const hasRestrictedItems = () => {
    return (
      intersection(map(selectedItems, "productId"), resctrictedItemIds).length >
      0
    );
  };

  const checkDeliveryAddress = (_) => {
    dispatch(
      restaurantsActions.checkRestaurantDelivery(
        restaurantData.id,
        formState.selectedAddress
      )
    );
  };

  const resetDeliveryCheck = (_) => {
    setError("");
    dispatch(restaurantsActions.resetDeliveryCheck());
  };

  const save = async (_) => {
    if (buttonDisabled) {
      return;
    }
    if (hasRestrictedItems()) {
      const productsIdsToRemove = intersection(
        map(selectedItems, "productId"),
        resctrictedItemIds
      );
      const basketProductIdsToRemove = productsIdsToRemove.map((productId) => {
        const product = selectedItems.find(
          (item) => item.productId === productId
        );
        return product.id;
      });

      for (const productId of basketProductIdsToRemove) {
        await dispatch(basketsActions.deleteProduct(basketGuid, productId));
      }
    }

    const earliestReadyTime = basketData && basketData.earliestreadytime;
    const hours = restaurantData && restaurantData.hours;
    await dispatch(basketsActions.updateBasket(basketGuid, formState, earliestReadyTime, hours));
    if(!fromSearch)  {
      setSaved(true);
    }
  };

  useEffect(() => {
    if(saved && !basketError) {
      onDismiss();
    }
  },[saved, basketError])

  useEffect(
    (_) => {
      if (
        formState.selectedAddress &&
        get(deliveryData, "candeliver") === undefined &&
        !restaurantLoading
      ) {
        checkDeliveryAddress();
      }
    },
    [formState.selectedAddress, deliveryData]
  );

  useEffect(
    (_) => {
      if (!deliveryData.candeliver) {
        setError(deliveryData.message);
      } else {
        setError("");
        formDispatch({ type: "address-verified" });
      }
    },
    [deliveryData.candeliver]
  );

  useEffect(
    (_) => {
      if (basketError) {
        setError(basketError);
      }
    },
    [basketError]
  );

  useEffect(
    (_) => {
      if (basketSaved) {
        dispatch(basketsActions.resetBasket());
        if (onSaved) {
          onSaved();
          onDismiss();
        }
      }
    },
    [basketSaved]
  );

  useEffect((_) => {
    resetDeliveryCheck();
    if (isEmpty(hoursData) && restaurantId) {
      dispatch(restaurantsActions.getHours(restaurantId));
    }
    return resetDeliveryCheck;
  }, []);

  const getTime = (days, open = false) => {
    const OLO_FORMAT = "YYYYMMDD HH:mm";
    const TIME_LABEL_FORMAT = "hh:mm a";
    const todayM = moment();
    const today = days.find(
      (day) => moment(day.start, OLO_FORMAT).date() === todayM.date()
    );
    if (today) {
      return open
        ? moment(today.start, OLO_FORMAT).format(TIME_LABEL_FORMAT)
        : moment(today.end, OLO_FORMAT).format(TIME_LABEL_FORMAT);
    }
    return null;
  };

  const renderSegmentedSelector = (_) => {
    if(isEsquire){
      return <></>;
    }
    
    else return (
    <div className="handoff-select-container">
      {!fromSearch && (
        <SegmentedSelector
          onSelect={(index) => {
            formDispatch({
              type: "handoff-selected",
              data: HANDOFF_TYPE_MAP[index],
            });
          }}
          selectedIndex={get(
            invert(HANDOFF_TYPE_MAP),
            formState.handoffMode,
            0
          )}
          containerClass="small"
          segmentClass="large"
        />
      )}
    </div>
  )};

  const renderEsquireCartWarning = () => {
    const isEsquire = isThemeEsquire();

    return (
      isEsquire && (
        <div className="esquire-cart-warning-container">
          <img src={InfoIcon} className="esquire-cart-warning-image" width="12px" alt="info icon"/>
          <div className="esquire-cart-warning-message">
            Switching from an Esquire location will clear your cart.
          </div>
        </div>
      )
    );
  };

  const renderDateTimeSelector = (containerClass = "", loading=false) => {
    if (isEmpty(hoursData)) {
      return null;
    }

    const businessHours = hoursData.find(entry => entry.type === "business");
    const dispatchHours = find(hoursData, {
        type:
          formState.handoffMode === "dispatch" ? "dispatch" : "curbsidepickup",
      }),
      dailyDispatchHours = get(dispatchHours, "ranges", []);
    return  (
      <DateTimeSelector
        selected={
          formState.selectedTimeMode === "asap"
            ? "asap"
            : formState.selectedTimeWanted
        }
        hours={dailyDispatchHours}
        onChange={(time) => {
          formDispatch({ type: "time-selected", data: time });
        }}
        loading={loading}
        containerClass={containerClass}
        earliestReadyTime={basketData && basketData.earliestreadytime}
        businessHours={businessHours}
        timezone={restaurantData && restaurantData.utcoffset}
      />
    );
  };
  const renderCurbside = (_) => {
    const curbsideHours = find(hoursData, { type: "curbsidepickup" }),
      dailyCurbsideHours = get(curbsideHours, "ranges", []);
    return (
      <div className={`handoff-menu-overlay-inner handoff-edit-curbside`} >
        <div className="handoff-scroll-container handoff-full-height">
          <div className="handoff-inner-wrap">
            <div className="handoff-overlay-title">
              {fromSearch
                ? `What time would you like your order?`
                : `Your order from Cooper's Hawk - ${restaurantData?.name}`}
            </div>
            <div className="handoff-overlay-description">
              {!fromSearch && (
                <>
                <Link to="/" className="different-location">
                  Select a different location
                </Link>
                {renderEsquireCartWarning()}
                </>
              )}
            </div>
            {renderSegmentedSelector()}
            {renderDateTimeSelector("mt-4")}
            {!!buttonDisabled && 
              <div style={{ color: "red", fontWeight: "bold" }}>Restaurant is closed for the day. Please select a
              different day.</div>
            }

            <button
              className={`handoff-save-btn ${!!buttonDisabled && "disabled"}`}
              onClick={save}
            >
              {basketLoading
                ? "Updating..."
                : fromSearch
                ? "Continue"
                : "Save Changes"}
            </button>
            <button className="handoff-cancel-btn" onClick={onDismiss}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };
  const renderDelivery = (_) => {
    const dispatchHours = find(hoursData, { type: "dispatch" }),
      dailyDispatchHours = get(dispatchHours, "ranges", []);
    return (
      <div className={`handoff-menu-overlay-inner handoff-edit-delivery ${esquireBorderClass}`}>
        <div className="handoff-scroll-container handoff-full-height">
          <div className="handoff-inner-wrap">
            <div className="handoff-overlay-title">
              {fromSearch
                ? `What time would you like your order?`
                : `Your order from Cooper's Hawk - ${restaurantData?.name}`}
            </div>
            <div className="handoff-overlay-description">
              {!fromSearch && (
                <>
                <Link to="/" className="different-location">
                  Select a different location
                </Link>
                {renderEsquireCartWarning()}
                </>
              )}
            </div>
            {renderSegmentedSelector()}
            {hasRestrictedItems() && (
              <div className="delivery-restriction-container">
                <img src={AlertIcon} alt="alert icon" />
                <div>
                  Alcohol items are not available for delivery. These items will
                  be removed from your cart when saved.
                </div>
              </div>
            )}
            {renderDateTimeSelector('paddingless mt-4')}
            {!!buttonDisabled && 
              <div style={{ color: "red", fontWeight: "bold" }}>Restaurant is closed for the day. Please select a
              different day.</div>
            }
            <div className="handoff-address-field-container mt-4">
              <div className="handoff-address-field handoff-input-field">
                {!formState.selectedAddress && (
                  <AutoComplete
                    value={formState.searchValue}
                    onChange={(e) => {
                      resetDeliveryCheck();
                      formDispatch({
                        type: "search-changed",
                        data: e.target.value,
                      });
                    }}
                    fields={[
                      "geometry.location",
                      "formatted_address",
                      "address_components",
                    ]}
                    apiKey={appConstants.googleMapsApiKey}
                    onPlaceSelected={(place) => {
                      try {
                        const addressComponents = parseAddressComponents(place);
                        formDispatch({
                          type: "address-selected",
                          data: addressComponents,
                        });
                        formDispatch({ type: "search-changed", data: "" });
                      } catch (e) {
                        setError(e.toString());
                        formDispatch({ type: "clear-search" });
                      }
                    }}
                    types={["address"]}
                    componentRestrictions={{ country: "us" }}
                    placeholder={"Delivery Address"}
                    type="search"
                  />
                )}
                {formState.selectedAddress && (
                  <>
                    <input
                      value={`${get(
                        formState.selectedAddress,
                        "streetaddress",
                        ""
                      )}, ${get(formState.selectedAddress, "city", "")}, ${get(
                        formState.selectedAddress,
                        "zipcode",
                        ""
                      )}`}
                      disabled
                    />
                    <button
                      className="handoff-clear-search"
                      onClick={(_) => {
                        formDispatch({ type: "clear-search" });
                      }}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="handoff-address-field-container mt-4">
              <div
                className="handoff-address-field handoff-input-field"
                style={{ marginRight: "1em" }}
              >
                <input
                  value={formState.dispatchAptNumber}
                  onChange={(e) => {
                    formDispatch({
                      type: "apt-changed",
                      data: e.target.value,
                    });
                  }}
                  placeholder={"Apt / Suite / Bldg"}
                  className="apt"
                />
              </div>
              <div className="handoff-address-field handoff-input-field">
                <input
                  value={formState.dispatchPhoneNumber}
                  onChange={(e) => {
                    formDispatch({
                      type: "phone-changed",
                      data: e.target.value.replace(/\D/g, ""),
                    });
                  }}
                  placeholder={"Phone Number"}
                  className="apt"
                />
              </div>
            </div>

            <div className="handoff-delivery-error-container">
              {error && <p>{error}</p>}
            </div>
            <button
             className={`handoff-save-btn mt-4${!!buttonDisabled &&
              " disabled"}`}
              onClick={save}
            >
              {basketLoading
                ? "Updating..."
                : fromSearch
                ? "Continue"
                : "Save Changes"}
            </button>
            <button className="handoff-cancel-btn" onClick={onDismiss}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };
  const renderPickup = (_) => {
    const busnessHours = find(hoursData, { type: "curbsidepickup" }),
      dailyBusnessHours = get(busnessHours, "ranges", []);
    return (
      <div className={`handoff-menu-overlay-inner handoff-edit ${esquireBorderClass}`}>
        <div className="handoff-scroll-container handoff-full-height">
          <div className="handoff-inner-wrap">
            <div className="handoff-overlay-title">
              {fromSearch
                ? `What time would you like your order?`
                : "What time would you like to pick up your order?"}
            </div>
            <div className="handoff-overlay-description">
              {!fromSearch && (
                <>
                <Link to="/" className="different-location">
                  Select a different location
                </Link>
                {renderEsquireCartWarning()}
                </>
              )}
            </div>
            {renderSegmentedSelector()}
            {renderDateTimeSelector("mt-4")}
            {!!buttonDisabled && 
              <div style={{ color: "red", fontWeight: "bold" }}>Restaurant is closed for the day. Please select a
              different day.</div>
            }
            <button
              className={`handoff-save-btn ${!!buttonDisabled && "disabled"}`}
              onClick={save}
            >
             {basketLoading
                ? "Updating..."
                : fromSearch
                ? "Continue"
                : "Save Changes"}

            </button>
            <button className="handoff-cancel-btn" onClick={onDismiss}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderLoader = (_) => (
    <div className="handoff-loader-wrapper handoff-is-active">
      <div className="handoff-loader handoff-is-loading" />
    </div>
  );
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      style={{ zIndex: 99 }}
    >
      <div className="handoff-menu-overlay">
        <>
          <div
            onClick={onDismiss}
            id="touch-layer"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          />
          {formState.handoffMode === "dispatch" && renderDelivery()}
          {formState.handoffMode === "curbside" && renderCurbside()}
          {formState.handoffMode === "pickup" && renderPickup()}
        </>
      </div>
    </motion.div>
  );
};

function mapStateToProps(state) {
  const {
    restaurants,
    restaurantSelected,
    delivery,
    basket,
    menu,
    hours,
    settings,
    member,
  } = state;
  const restaurantSelectedData = get(restaurantSelected, "data", {});
  const restaurantLoading = get(restaurantSelected, "loading");
  const deliveryData = get(delivery, "data", {});
  const basketData = get(basket, "data", {});
  const basketLoading = get(basket, "loading");
  const basketSaved = get(basket, "saved");
  const basketError = get(basket, "error");
  const menuData = get(menu, "data", {});
  const memberData = get(member, "data", {});
  const lastLocation = get(memberData, "lastLocation", {});
  const lastSlug = get(lastLocation, "CHW_OLO_Slug__c", "");
  const homeLocation =
    restaurants.data &&
    restaurants.data.restaurants &&
    restaurants.data.restaurants.find(
      (restaurant) => restaurant.slug === lastSlug
    );

  const restaurantData = isEmpty(restaurantSelectedData)
    ? homeLocation
    : restaurantSelectedData;
  const hoursData = get(restaurantData, "hours", []);

  return {
    restaurantData,
    restaurantLoading,
    deliveryData,
    basketData,
    basketLoading,
    basketSaved,
    basketError,
    menuData,
    hoursData,
    settings,
  };
}

const connectedHandoffModal = connect(mapStateToProps)(HandoffModal);
export { connectedHandoffModal as HandoffModal };
