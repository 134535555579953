import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header, CholoInput } from '../../components';
import Loader from "react-loader-spinner";
import { Orders } from '../../_services/orders.service';
import { alertActions} from '../../_actions';
import { useDispatch } from 'react-redux'
import { get } from 'lodash';
import { useRenameDocument } from '../../context/renameDocument';
import './styles.scoped.scss';

const Checkin = ({history}) => {
  const scrollContainerRef = useRef(null);
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [color, setColor] = useState('');
  const [parkingLocation, setParkingLocation] = useState('');
  const [posting, setPosting] = useState(false);
  const [success, setSuccess] = useState(false);
  let { orderGuid } = useParams();
  const CHAR_LIMIT = 128;
  const dispatch = useDispatch();
  useRenameDocument('Curbside Checkin');

  useEffect(() => {
    setSuccess(window.location.hash === '#arrived');
  }, [window.location.hash])

  const getOrder = async () => {
    try {
      const result = await Orders.refreshOrderByGuid(orderGuid);
      const { customfields } = result;
      const makeCustomField = customfields?.find(
        customField =>
          customField.label && customField.label.toLowerCase() === 'make',
      );
      const modelCustomField = customfields?.find(
        customField =>
          customField.label && customField.label.toLowerCase() === 'model',
      );
      const colorCustomField = customfields?.find(
        customField =>
          customField.label && customField.label.toLowerCase() === 'color',
      );
      setMake(makeCustomField?.value);
      setModel(modelCustomField?.value);
      setColor(colorCustomField?.value);
    } catch (e) {
      dispatch(alertActions.oloerror(e.message));
    }
  };

  useEffect(() => {
    if(orderGuid) {
      getOrder();
    }
  }, [orderGuid])

  const post = async () => {
    setPosting(true);
    try {
      const data = await Orders.pickupOrder(orderGuid, { message: parkingLocation});
      // handle data
      setPosting(false);
      history.replace(`/order/arrival/${orderGuid}#arrived`);
      scrollContainerRef.current.scrollTop = 0;
    } catch (e) {
      setPosting(false);
     // handle error e.message
     dispatch(alertActions.oloerror(e.message))
    }
  }

  return (
    <div className="arrival-container d-flex flex-column w-100">
      <Header hideBag hideLogin disableHome />
      <div className="scroll-container" ref={scrollContainerRef}>
      <div className="inner-container">
        {success ? 
          <>
          <div className="title">Success!</div>
          <div className="subtitle">
          Thanks for letting us know you've arrived. We will bring out your order shortly. 
          </div>
          </>
 :
<>
          <div className="title">Check in</div>
          <div className="subtitle">
            In order to complete the check-in, please tell us your parking spot
            number or where you are located.
          </div>
          </>}
          <div className='input-wrap'>
          {!success && <CholoInput
            placeholder="Enter Parking Location"
            onTextChanged={text => text.length <= CHAR_LIMIT ? setParkingLocation(text) : null}
            value={parkingLocation}
          />}
          {!success && <div className={`characters ${parkingLocation.length >= 118 ? 'red' : ''}`}>{CHAR_LIMIT - parkingLocation.length}</div>}
          </div>
          <div className='bg'>
          {success && 
          <>
          <p className="section-title d-flex flex-row p-4 pb-1 mb-0">PARKING LOCATION</p>
          <div className='subtitle text-left pl-4 mb-0'>{parkingLocation}</div>
          <hr className='ml-3'/>
          </>}
           {/* make, model and color are all required and empty by default so if make is not set, something went wrong fetching these values, don't show car details */}
          {make !== '' && <div className='section'>
          <div className="section-title d-flex flex-row p-4">CAR DETAILS</div>
          <div className="detail-item d-flex flex-row ml-4 mr-4 justify-content-start">
            <div className="d-flex flex-column details mr-3">
             Make:
            </div>
            <div className="d-flex flex-column section-title">
              {make}
            </div>
          </div>
          <div className="detail-item d-flex flex-row ml-4 mr-4 justify-content-start">
            <div className="d-flex flex-column details mr-3">
             Model:
            </div>
            <div className="d-flex flex-column section-title">
              {model}
            </div>
          </div>
          <div className="detail-item d-flex flex-row ml-4 mr-4 justify-content-start">
            <div className="d-flex flex-column details mr-3">
              Color:
            </div>
            <div className="d-flex flex-column section-title">
              {color}
            </div>
          </div>
          </div>}
          </div>
         
          <div className="d-flex flex-row mt-4">
          {!success ?  <button onClick={post} className={`create-account-btn col-12 ${parkingLocation.length===0 ? 'disabled' : ''}`} disabled={parkingLocation.length===0 || posting}>
              CHECK IN
            </button> : <span className='spacer'/>}
          </div>
        </div>
        {posting && <Loader
          style={{ position: 'absolute', marginTop: "25vh",}}
          type="ThreeDots"
          color="#735D2Faa"
          height={60}
          width={60}
        />}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { order } = state;
  const orderData = get(order, 'data', null);

  return {
    orderData,
  };
}

const connectedCheckin = withRouter(connect(mapStateToProps)(Checkin));
export { connectedCheckin as Checkin };
